import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createWebsitePartnerAction,
  deleteWebsitePartnerAction,
  getSingleWebsitePartnerAction,
  getWebsitePartnersAction,
  updateWebsitePartnerAction
} from './websitePartnersSliceThunkApi';

const initialState = {
  /////////////////////
  fetchedSinglePartner: null,
  isLoadingTableData: false,
  fetchedTableData: [],
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  },
  fetchDataCount: 0,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  confirmModal: {
    opened: false,
    type: ''
  }
};

const websitePartnersSlice = createSlice({
  name: 'websitePartnersSlice',
  initialState,
  reducers: {
    setFetchDataCount: (sliceState, action) => {
      sliceState.fetchDataCount = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setConfirmModal: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    }
  },
  extraReducers: {
    // get partners
    [getWebsitePartnersAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getWebsitePartnersAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data?.data;
      state.fetchedTableData = data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getWebsitePartnersAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    // get single partner
    [getSingleWebsitePartnerAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getSingleWebsitePartnerAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSinglePartner = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getSingleWebsitePartnerAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    // create parnter
    [createWebsitePartnerAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createWebsitePartnerAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [createWebsitePartnerAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // update partner
    [updateWebsitePartnerAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateWebsitePartnerAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [updateWebsitePartnerAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    // delete partner
    [deleteWebsitePartnerAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteWebsitePartnerAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteWebsitePartnerAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const websitePartnersReducer = websitePartnersSlice.reducer;

export const {
  setPostFormModalOpened,
  setFetchDataCount,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setSelectedTab
} = websitePartnersSlice.actions;

export default websitePartnersReducer;
