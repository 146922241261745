import axios from 'axios';
import apisUrls from '../urls';

export const createWebsiteExperienceApi = (values, edit = false) => {
  const url = !edit
    ? apisUrls?.createWebsiteExperienceUrl
    : apisUrls?.updateWebsiteExperienceUrl;

  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteWebsiteExperienceApi = (values) => {
  return axios.post(apisUrls?.deleteWebsiteExperienceUrl, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteWebsiteExperienceImageApi = (values) => {
  return axios.post(apisUrls?.deleteWebsiteExperienceImageUrl, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getAllWebsiteExperiencesApi = (filterObj) => {
  return axios.get(apisUrls?.getWebsiteExperiencesUrl(filterObj));
};

export const getSingleWebsiteExperienceApi = (filterObj) => {
  return axios.get(apisUrls?.getSingleWebsiteExperienceUrl(filterObj));
};
