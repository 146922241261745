const adminTypesValues = {
  primaryAdmin: 1
};

export const adminRolesObj = {
  viewStatisticsRole: 1,
  controlSettingsRole: 2,
  controlAppClientsRole: 3,
  controlAppAgentsRole: 4,
  controlAppPackagesRole: 5,
  controlAppPaymentMethodsRole: 6,
  controlAppArchiveRole: 7,
  controlWebsiteRole: 8
};

export const getAdminRolesArr = (t) => {
  return [
    {
      id: 1,
      name: 'متابعة الاحصائيات',
      value: adminRolesObj?.viewStatisticsRole
    },
    {
      id: 2,
      name: 'التحكم فى الاعدادات',
      value: adminRolesObj?.controlSettingsRole
    },
    {
      id: 3,
      name: 'التحكم فى عملاء الادمن',
      value: adminRolesObj?.controlAppClientsRole
    },
    {
      id: 4,
      name: 'التحكم فى مسوقين التطبيق',
      value: adminRolesObj?.controlAppAgentsRole
    },
    {
      id: 5,
      name: 'التحكم فى باقات التطبيق',
      value: adminRolesObj?.controlAppPackagesRole
    },
    {
      id: 6,
      name: 'التحكم فى طرق الدفع',
      value: adminRolesObj?.controlAppPaymentMethodsRole
    },
    {
      id: 7,
      name: 'متابعة العمليات المؤرشفة',
      value: adminRolesObj?.controlAppArchiveRole
    },
    {
      id: 8,
      name: 'التحكم فى الموقع الالكترونى',
      value: adminRolesObj?.controlWebsiteRole
    }
  ];
};

export default adminTypesValues;
