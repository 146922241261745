import { Image, Text, View } from '@react-pdf/renderer';
import React, { useEffect } from 'react';

const InvoiceMainFooter = ({ data, imageUrl, zakatQr }) => {
  const [words, setWords] = React.useState([]);
  useEffect(() => {
    setWords(data?.setting?.accountInfo?.commercialRecordName?.split(' '));
  }, [data]);
  return (
    <View
      style={{
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: '#bbb',
        padding: '0 22',
        paddingTop: 22,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 'auto'
      }}
    >
      <View
        style={{
          width: '48%',
          display: 'flex',
          alignContent: 'flex-end',
          alignItems: 'flex-end',
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 'bold',
          textOverflow: 'wrap'
        }}
      >
        <Text
          style={{
            marginBottom: 14,
            color: '#292B30'
          }}
        >
          {data?.setting?.invoiceSetting?.footerText || '---'}
        </Text>
        {words.length > 3 ? (
          <>
            <Text
              style={{
                marginTop: 4,
                color: '#292B30',
                fontWeight: 'bold'
              }}
            >
              {`نشكرك لتسوقك من ${words.slice(0, 3).join(' ')}`}
            </Text>
            <Text
              style={{
                marginTop: 4,
                color: '#292B30',
                fontWeight: 'bold'
              }}
            >
              {`${words.slice(3, words.length + 1).join(' ')}`}
            </Text>
          </>
        ) : (
          <Text
            style={{
              marginTop: 4,
              color: '#292B30',
              fontWeight: 'bold'
            }}
          >
            {`نشكرك لتسوقك من ${words.join(' ')}`}
          </Text>
        )}

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Text>Powered by</Text>
          <Image
            style={{
              width: 36,
              height: '12',
              marginLeft: 4,
              marginTop: 6
            }}
            src="/assets/imgs/logo/logo-footer.png"
          />
        </View>
      </View>
      <View
        style={{
          width: '48%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <View
          style={{
            width: 100
          }}
        >
          <Image
            style={{
              width: 100,
              height: 100,
              marginLeft: 4,
              marginTop: 6
            }}
            src={imageUrl}
          />
          <Text
            style={{
              fontSize: 10,
              textAlign: 'center',
              transform: 'translateX(8px)'
            }}
          >
            MyCash QR
          </Text>
        </View>
        <View
          style={{
            width: 100
          }}
        >
          <Image
            style={{
              width: 100,
              height: 100,
              marginLeft: 4,
              marginTop: 6
            }}
            src={zakatQr}
          />
          <Text
            style={{
              fontSize: 10,
              textAlign: 'center',
              transform: 'translateX(8px)'
            }}
          >
            ZATCA QR{' '}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default InvoiceMainFooter;
