/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import './MainAppBar.scss';
import MainAppBarLg from './MainAppBarLg';
import MainAppBarMd from './MainAppBarMd';

const MainAppBar = () => {
  return (
    <>
      <MainAppBarLg />

      <MainAppBarMd />
    </>
  );
};

export default MainAppBar;
