import HomeIcon from '../../common/icons/HomeIcon';
// import TransferIcon from '../../common/icons/TransferIcon';
import routerLinks from '../app/routerLinks';
// import ProjectsIcons from '../../common/icons/ProjectsIcons';
// import CRMIcon from '../../common/icons/CRMIcon';
// import PosIcon from '../../common/icons/PosIcon';
// import WarehouseIcon from '../../common/icons/WarehouseIcon';
import AppbarSettingsIcon from '../../common/icons/AppbarSettingsIcon';
import CheckmarkIcon from '../../common/icons/CheckmarkIcon';
import AgentsIcon from '../../common/icons/AgentsIcon';
import ClientsIcon from '../../common/icons/ClientsIcon';
import CrownIcon from '../../common/icons/CrownIcon';
import HelpIcon from '../../common/icons/HelpIcon';
import PartnersIcon from '../../common/icons/PartnersIcon';
import ServicesIconAppbar from '../../common/icons/ServicesIconAppbar';
import VideoIcon from '../../common/icons/VideoIcon';
import WalletIconAppbar from '../../common/icons/WalletIconAppbar';
import ArchiveIcon from '../../common/icons/ArchiveIcon';
const sharedAppBarLinks = [
  {
    id: 1,
    to: routerLinks.homeRoute || '',
    name: 'الرئيســية',
    isLink: true,
    icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 2,
    to: routerLinks.settingsRoute || '',
    name: 'الاعدادات',
    isLink: true,
    icon: (color) =>
      color ? <AppbarSettingsIcon color={color} /> : <AppbarSettingsIcon />,
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 'app_text_id',
    to: '',
    name: 'تطبيق Mycash',
    isSeparator: true,
    isLink: false,
    icon: null,
    subMenu: [],
    visibleSubMenu: false
  },   {
    id: 'website_requests',
    to: routerLinks.websiteRequests || '',
    name: ' طلبات من الموقع',
    isLink: true,
    icon: (color) =>
      color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
    subMenu: [],
    visibleSubMenu: false
  },
  
  
  {
    id: 'Admin_id',
    to: routerLinks.appAdminRoute || '',
    name: 'مسؤولين',
    isLink: true,
    icon: (color) => (color ? <ClientsIcon color={color} /> : <ClientsIcon />),
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 'clients_id',
    to: routerLinks.appClientsRoute || '',
    name: 'العملاء',
    isLink: true,
    icon: (color) => (color ? <ClientsIcon color={color} /> : <ClientsIcon />),
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 'agents_id',
    to: routerLinks.appAgentsRoute || '',
    name: 'المسوقين',
    isLink: true,
    icon: (color) => (color ? <AgentsIcon color={color} /> : <AgentsIcon />),
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 'plans_id',
    to: routerLinks.appPackagesRoute || '',
    name: 'الباقات',
    isLink: true,
    icon: (color) =>
      color ? <AppbarSettingsIcon color={color} /> : <AppbarSettingsIcon />,
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 'devices_id',
    to: routerLinks.devicesRoute || '',
    name: 'الاجهزة',
    isLink: true,
    icon: (color) =>
      color ? <ServicesIconAppbar color={color} /> : <ServicesIconAppbar />,
    subMenu: [],
    visibleSubMenu: false
  },  
   {
    id: 'country_id',
    to: routerLinks.appCountryRoute || '',
    name: 'دول',
    isLink: true,
    icon: (color) =>
      color ? <AppbarSettingsIcon color={color} /> : <AppbarSettingsIcon />,
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 'payment_options_id',
    to: routerLinks.appPaymentTypesRoute || '',
    name: 'طرق الدفع',
    isLink: true,
    icon: (color) =>
      color ? <WalletIconAppbar color={color} /> : <WalletIconAppbar />,
    subMenu: [],
    visibleSubMenu: false
  },
  // {
  //   id: 'archive_id',
  //   to: routerLinks.appArchivesRoute || '',
  //   name: 'العمليات المؤرشفة',
  //   isLink: true,
  //   icon: (color) =>
  //     color ? <AppbarSettingsIcon color={color} /> : <AppbarSettingsIcon />,
  //   subMenu: [],
  //   visibleSubMenu: false
  // },
  {
    id: 'guide_id',
    to: routerLinks.appGuideRoute || '',
    name: 'دليل التطبيق',
    isLink: true,
    icon: (color) =>
      color ? <ServicesIconAppbar color={color} /> : <ServicesIconAppbar />,
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 'website_text_id',
    to: '',
    name: 'الموقع الإلكتروني',
    isSeparator: true,
    isLink: false,
    icon: null,
    subMenu: [],
    visibleSubMenu: false
  },
  // {
  //   id: 'website_id',
  //   to: routerLinks.websiteAdminPanelRoute || '',
  //   name: 'لوحة العرض',
  //   isLink: true,
  //   icon: (color) => (color ? <VideoIcon color={color} /> : <VideoIcon />),
  //   subMenu: [],
  //   visibleSubMenu: false
  // },
  // {
  //   id: 'services_id',
  //   to: routerLinks.websitesServicesRoute || '',
  //   name: 'خدماتنا',
  //   isLink: true,
  //   icon: (color) =>
  //     color ? <ServicesIconAppbar color={color} /> : <ServicesIconAppbar />,
  //   subMenu: [],
  //   visibleSubMenu: false
  // },
  // {
  //   id: 'services_features_id',
  //   to: routerLinks.websitesServicesFeatruesRoute || '',
  //   name: 'مميزات الخدمات',
  //   isLink: true,
  //   icon: (color) =>
  //     color ? <ServicesIconAppbar color={color} /> : <ServicesIconAppbar />,
  //   subMenu: [],
  //   visibleSubMenu: false
  // },
  {
    id: 'our_partners_id',
    to: routerLinks.websitePartnersRoute || '',
    name: 'شركاؤنا',
    isLink: true,
    icon: (color) =>
      color ? <PartnersIcon color={color} /> : <PartnersIcon />,
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 'contracts_id',
    to: routerLinks.websitePackagesRoute || '',
    name: 'الباقات والاشتراكات',
    isLink: true,
    icon: (color) => (color ? <CrownIcon color={color} /> : <CrownIcon />),
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 'help_id',
    to: routerLinks.websiteContactsRoute || '',
    name: 'المساعدة',
    isLink: true,
    icon: (color) => (color ? <HelpIcon color={color} /> : <HelpIcon />),
    subMenu: [],
    visibleSubMenu: false
  },
  // {
  //   id: 'start_exp_id',
  //   to: routerLinks.websiteStartExpRoute || '',
  //   name: 'إبدأ تجربتك',
  //   isLink: true,
  //   icon: (color) =>
  //     color ? <CheckmarkIcon color={color} /> : <CheckmarkIcon />,
  //   subMenu: [],
  //   visibleSubMenu: false
  // }
];

export default sharedAppBarLinks;
