/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useContext } from 'react';
import { Menu, Dropdown, Spin, Badge } from 'antd';
import MyNotificationsContext from '../../../contexts/notifications-context/MyNotificationsContext';
import handleClickNotification from '../../../utils/handleClickNotification';
import UserContext from '../../../contexts/user-context/UserProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useReadNotification from '../../../custom-hooks/useReadNotification';
import NotificationsIcon from '../../../common/icons/NotificationsIcon';

const AppNotificationsDropdownMenu = ({ parentClassName }) => {
  const [settingsClicked, setSettingsClicked] = React.useState(false);
  const { isLoadingNotifications, myFetchedNotifications, setFetchCount } =
    useContext(MyNotificationsContext);
  const divRef = useRef();
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { readNotification } = useReadNotification();
  const renderNotificationsMenue = () => {
    if (isLoadingNotifications) {
      return [
        {
          key: 'loading-item',
          label: (
            <div
              className="notification-content"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Spin />
            </div>
          )
        }
      ];
    } else if (myFetchedNotifications?.length > 0) {
      return myFetchedNotifications.map((item) => ({
        key: item?.id,
        label: (
          <div
            onClick={() => {
              handleClickNotification({
                obj: item,
                user,
                navigate,
                setFetchCount: setFetchCount,
                readNotification
              });
            }}
            className="notifications-menu-link"
          >
            <div
              className={`notification-content ${
                item?.isRead === 0 ? 'not-read' : 'read'
              }`}
            >
              <div className="notification-img" ref={divRef}>
                <img src="/assets/imgs/logo/logo.png" alt="icon" />
              </div>
              <div className="notification-data">
                {/* <div className="notification-title">
                    {i18n.language === 'ar'
                      ? 'إشعار جديد'
                      : 'New Notifications'}
                  </div> */}
                <div className="notification-desc">{item?.desc}</div>

                {item?.isRead === 0 ? (
                  <span className={`not-read-indicator ${i18n.dir()}`} />
                ) : null}
              </div>
            </div>
          </div>
        )
      }));
    }
    if (
      !isLoadingNotifications &&
      (!myFetchedNotifications || myFetchedNotifications?.length === 0)
    ) {
      return [
        {
          key: 'empty-list',
          label: (
            <div className="notifications-menu-link">
              <div className="notification-content">لا يوجد إشعارات</div>
            </div>
          )
        }
      ];
    }
    return [
      {
        key: 'null',
        label: <div className="notifications-menu-link"></div>
      }
    ];
  };

  const getUnReadNotificationsCount = () => {
    if (myFetchedNotifications?.length > 0) {
      return myFetchedNotifications.filter((item) => {
        return item?.isRead === 0;
      })?.length;
    }
    return 0;
  };

  return (
    <Dropdown
      getPopupContainer={() => document.querySelector(`.${parentClassName}`)}
      menu={{
        items: renderNotificationsMenue()
      }}
      trigger={['click']}
      arrow
      placement="bottomLeft"
      overlayClassName="notifications-dropdown-wrap"
      onOpenChange={() => {
        setSettingsClicked((prevState) => !prevState);
      }}
    >
      <div
        className={`notification-btn-wrap ${settingsClicked ? 'clicked' : ''}`}
      >
        <Badge count={getUnReadNotificationsCount()}>
          <NotificationsIcon />
        </Badge>
        {myFetchedNotifications?.length > 0 && (
          <span className="active-notifications"></span>
        )}
      </div>
    </Dropdown>
  );
};

export default AppNotificationsDropdownMenu;
