/* eslint-disable eqeqeq */

import CustomImage from '../../common/custom-image/CustomImage';
import medalIcon from '../../assets/imgs/icons/packages-icons/medal.svg';
import posIcon from '../../assets/imgs/icons/packages-icons/pos-icon.svg';
import CommonTag from '../../common/common-tag/CommonTag';
import EditIcon from '../../common/icons/EditIcon';

const PaymentTypesTableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  setConfirmModal,
  setDeleteModalOpened,
  i18n,
  t,
  ...props
}) => {
  const handleEdit = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <button
            className="action-btn edit"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="#196c10" />
          </button>
        </div>
      </div>
    );
  };

  return [
    {
      title: '',
      dataIndex: 'id',
      render: (_, record) => {
        return <p>{`# ${record.id}`}</p>;
      }
    },
    {
      title: 'الصورة',
      dataIndex: 'image',
      render: (_, record) => {
        return (
          <div className="table-img-wrapper">
            <CustomImage className="table-package-img" src={record?.icon} />
          </div>
        );
      }
    },
    {
      title: 'الاسم',
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name || '----';
      }
    },
    {
      title: 'تعديل',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default PaymentTypesTableColumns;
