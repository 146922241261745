import axios from 'axios';
import apisUrls from '../urls';

const getSingleClientApi = (paramsFilter) => {
  return axios.get(
    `${apisUrls?.getSingleClient}?user_id=${paramsFilter?.client_id || ''}`
  );
};

export default getSingleClientApi;
