import CustomImage from '../../common/custom-image/CustomImage';
import calenderImg from '../../assets/imgs/profile-images/calendar-icon.svg';
import mobileImg from '../../assets/imgs/profile-images/mobile-icon.svg';
import mailImg from '../../assets/imgs/profile-images/mail-icon.svg';
import './ClientInfoSection.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import ReceiptPrintIcon from '../../common/icons/ReceiptPrintIcon';

const BoxStat = ({ icon, value, title }) => {
  return (
    <div className="box-stat">
      <div className="icon-wrap">{icon}</div>
      <div className="value-wrap">{value}</div>
      <div className="title-wrap">{title}</div>
    </div>
  );
};

const SingleInfo = ({ img, value }) => {
  return (
    <div className="single-info-box">
      <img src={img} alt="info" />
      <p>{value}</p>
    </div>
  );
};

const ClientInfoSection = ({ fetchedSingleClient }) => {
  const clientInfo = fetchedSingleClient;
  return (
    <div className="client-info-stats-sections">
      <section className="stats-section">
        <CustomImage className="profile-img" src={clientInfo?.image} />
        <div className="stats-wrapper">
          <div className="name-email-edit-wrapper">
            <p className="name">{clientInfo?.name}</p>
            <p className="email">{clientInfo?.email}</p>
          </div>

          <div className="stats-boxs">
            {/* <BoxStat
              icon={<img src={imagesSrcs?.usersGroup} alt="users" />}
              value={clientInfo?.userCounts || '-'}
              title="مستخدم"
            /> */}
            <BoxStat
              icon={<ReceiptPrintIcon />}
              value={clientInfo?.invoiceCounts || '-'}
              title="فواتير"
            />
            <BoxStat
              icon={<img src={imagesSrcs?.products} alt="products" />}
              value={clientInfo?.productsCount || '-'}
              title="منتجات"
            />
          </div>
        </div>
      </section>

      <section className="profile-info-section">
        <h4>بيانات الحساب</h4>

        <div className="infos-wrapper">
          {clientInfo?.subscription?.startDate && (
            <SingleInfo
              img={calenderImg}
              value={clientInfo.subscription.startDate}
            />
          )}
          {clientInfo?.subscription?.endDate && (
            <SingleInfo
              img={calenderImg}
              value={clientInfo.subscription.endDate}
            />
          )}
          <SingleInfo img={mobileImg} value={clientInfo?.phone || '-'} />
          <SingleInfo img={mailImg} value={clientInfo?.email || '-'} />
          {/* <SingleInfo img={imagesSrcs?.copoun} value="esalkf@ksj.com" /> */}
        </div>
      </section>
    </div>
  );
};

export default ClientInfoSection;
