import { createAsyncThunk } from '@reduxjs/toolkit';
import getAllPaymentTypesApi from '../../apis/payment-types-apis/getAllPaymentTypesApi';
import updatePaymentTypeApi, {
  createPaymentTypeApi
} from '../../apis/payment-types-apis/updatePaymentTypeApi';
import customApiRequest from '../../helpers/customApiRequest';

export const getPaymentTypesAction = createAsyncThunk(
  'paymentTypes/getPaymentTypesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllPaymentTypesApi({ ...filterObj }),
      thunkAPI
    });
  }
);

export const updatePaymentTypeAction = createAsyncThunk(
  'paymentTypes/updatePaymentTypeAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updatePaymentTypeApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const createPaymentTypeActions = createAsyncThunk(
  'paymentTypes/createPaymentTypeAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createPaymentTypeApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
