export const incrementCounterAction = (state) => {
  state.count += 1;
};

export const decrementCounterAction = (state) => {
  state.count -= 1;
};

export const incrementByAmountAction = (state, action) => {
  state.count += action.payload;
};
