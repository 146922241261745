import React from 'react';

const DeactivateIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00675 15.16C7.58675 15.16 7.17341 15.02 6.84675 14.74L5.79341 13.84C5.68674 13.7467 5.42008 13.6533 5.28008 13.6533H4.11341C3.12674 13.6533 2.32674 12.8533 2.32674 11.8667V10.7267C2.32674 10.5867 2.23341 10.32 2.14674 10.22L1.24008 9.15333C0.693411 8.50667 0.693411 7.49333 1.24008 6.84L2.14674 5.77333C2.23341 5.67333 2.32674 5.40667 2.32674 5.26667V4.13333C2.32674 3.14667 3.12674 2.34667 4.11341 2.34667H5.26675C5.40674 2.34667 5.66675 2.24667 5.78008 2.15333L6.83341 1.25333C7.48674 0.7 8.50674 0.7 9.16008 1.25333L10.2134 2.15333C10.3201 2.24667 10.5934 2.34 10.7334 2.34H11.8667C12.8534 2.34 13.6534 3.14 13.6534 4.12667V5.26C13.6534 5.4 13.7534 5.66 13.8467 5.77333L14.7467 6.82667C15.3067 7.48667 15.3001 8.50667 14.7467 9.15333L13.8467 10.2067C13.7534 10.32 13.6601 10.58 13.6601 10.72V11.8533C13.6601 12.84 12.8601 13.64 11.8734 13.64H10.7401C10.6001 13.64 10.3401 13.74 10.2201 13.8333L9.16675 14.7333C8.84008 15.02 8.42008 15.16 8.00675 15.16ZM4.11341 3.34667C3.68008 3.34667 3.32674 3.7 3.32674 4.13333V5.26667C3.32674 5.64667 3.15341 6.12667 2.90674 6.42L2.00008 7.48667C1.77341 7.76 1.77341 8.24 2.00008 8.50667L2.90008 9.56667C3.14008 9.84 3.32008 10.34 3.32008 10.72V11.86C3.32008 12.2933 3.67341 12.6467 4.10674 12.6467H5.26675C5.64008 12.6467 6.13341 12.8267 6.42675 13.0733L7.48674 13.98C7.76008 14.2133 8.24008 14.2133 8.51341 13.98L9.56675 13.08C9.86674 12.8267 10.3534 12.6533 10.7267 12.6533H11.8601C12.2934 12.6533 12.6467 12.3 12.6467 11.8667V10.7333C12.6467 10.36 12.8267 9.87333 13.0734 9.57333L13.9801 8.51333C14.2134 8.24 14.2134 7.76 13.9801 7.48667L13.0801 6.43333C12.8267 6.13333 12.6534 5.64667 12.6534 5.27333V4.13333C12.6534 3.7 12.3001 3.34667 11.8667 3.34667H10.7334C10.3534 3.34667 9.86008 3.16667 9.56675 2.92L8.50675 2.01333C8.23341 1.78 7.76008 1.78 7.48008 2.01333L6.43341 2.92C6.13341 3.16667 5.64675 3.34667 5.26675 3.34667H4.11341Z"
        fill={color || '#404041'}
      />
      <path
        d="M8.00016 11.2467C7.6335 11.2467 7.3335 10.9467 7.3335 10.58C7.3335 10.2133 7.62683 9.91333 8.00016 9.91333C8.36683 9.91333 8.66683 10.2133 8.66683 10.58C8.66683 10.9467 8.3735 11.2467 8.00016 11.2467Z"
        fill={color || '#404041'}
      />
      <path
        d="M8 9.14666C7.72667 9.14666 7.5 8.92 7.5 8.64666V5.42C7.5 5.14666 7.72667 4.92 8 4.92C8.27333 4.92 8.5 5.14666 8.5 5.42V8.64C8.5 8.92 8.28 9.14666 8 9.14666Z"
        fill={color || '#404041'}
      />
    </svg>
  );
};

export default DeactivateIcon;
