/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import slugify from 'slugify';
import MainAppBarContext from '../contexts/main-app-bar-context/MainAppBarProvider';

const ActiveAppBarLink = () => {
  const { pathname } = useLocation();
  const { links, setActiveLink } = useContext(MainAppBarContext);

  useEffect(() => {
    setActiveLink(links[0]);
  }, []);

  return useEffect(() => {
    let found = null;
    found = links.find(
      (mainLink) => slugify(mainLink.to) === slugify(pathname)
    );

    for (let mainLink of links) {
      if (mainLink?.subMenu?.length > 0) {
        const foundSub = mainLink.subMenu.find(
          (sub) => slugify(sub.to) === slugify(pathname)
        );
        if (foundSub) found = mainLink;
      }
    }
    setActiveLink(found);
  }, [pathname]);
  // return useEffect(() => {
  //   for (let mainLink of links) {
  //     if (slugify(mainLink.to) === slugify(pathname)) {
  //       setActiveLink(mainLink);
  //       break;
  //     } else if (mainLink?.subMenu?.length > 0) {
  //       for (let subLink of mainLink.subMenu) {
  //         if (slugify(subLink.to) === slugify(pathname)) {
  //           setActiveLink(mainLink);
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }, [pathname]);
};

export default ActiveAppBarLink;
