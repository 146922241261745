const generalRouterLinks = {
  generalProductsRoute: '/products',
  generalSingleProductRoute: (productId) => {
    if (productId) return `/products/${productId}`;
    return '/products/:productId';
  },
  generalAffiliateProductRoute: (productId, agentKey) => {
    if (productId && agentKey) return `/products/${productId}/${agentKey}`;
    return '/products/:productId/:agentKey';
  },
  blogsRoute: '/blogs',
  singleBlogRoute: (blogId) => {
    if (blogId) return `/blogs/${blogId}`;
    return '/blogs/:blogId';
  },
  refundTermsRoute: '/refund-terms'
};

export default generalRouterLinks;
