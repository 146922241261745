import React from 'react';

const ActivateIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.19321 10.1133C7.05988 10.1133 6.93321 10.06 6.83988 9.96667L5.22654 8.35334C5.03321 8.16 5.03321 7.84 5.22654 7.64667C5.41988 7.45334 5.73988 7.45334 5.93321 7.64667L7.19321 8.90667L10.0599 6.04C10.2532 5.84667 10.5732 5.84667 10.7665 6.04C10.9599 6.23334 10.9599 6.55334 10.7665 6.74667L7.54654 9.96667C7.45321 10.06 7.32654 10.1133 7.19321 10.1133Z"
        fill={color || '#fff'}
      />
      <path
        d="M7.99976 15.1667C7.57976 15.1667 7.15976 15.0267 6.83309 14.7467L5.77976 13.84C5.67309 13.7467 5.40643 13.6533 5.26643 13.6533H4.11976C3.13309 13.6533 2.33309 12.8533 2.33309 11.8667V10.7267C2.33309 10.5867 2.23976 10.3267 2.14643 10.22L1.24643 9.16C0.699759 8.51334 0.699759 7.49334 1.24643 6.84667L2.14643 5.78667C2.23976 5.68 2.33309 5.42 2.33309 5.28V4.13334C2.33309 3.14667 3.13309 2.34667 4.11976 2.34667H5.27309C5.41309 2.34667 5.67976 2.24667 5.78643 2.16L6.83976 1.25334C7.49309 0.693336 8.51309 0.693336 9.16643 1.25334L10.2198 2.16C10.3264 2.25334 10.5931 2.34667 10.7331 2.34667H11.8664C12.8531 2.34667 13.6531 3.14667 13.6531 4.13334V5.26667C13.6531 5.40667 13.7531 5.67334 13.8464 5.78L14.7531 6.83334C15.3131 7.48667 15.3131 8.50667 14.7531 9.16L13.8464 10.2133C13.7531 10.32 13.6531 10.5867 13.6531 10.7267V11.86C13.6531 12.8467 12.8531 13.6467 11.8664 13.6467H10.7331C10.5931 13.6467 10.3264 13.7467 10.2198 13.8333L9.16643 14.74C8.83976 15.0267 8.41976 15.1667 7.99976 15.1667ZM4.11976 3.34667C3.68643 3.34667 3.33309 3.7 3.33309 4.13334V5.27334C3.33309 5.65334 3.15309 6.14 2.90643 6.42667L2.00643 7.48667C1.77309 7.76 1.77309 8.23334 2.00643 8.50667L2.90643 9.56667C3.15309 9.86 3.33309 10.34 3.33309 10.72V11.86C3.33309 12.2933 3.68643 12.6467 4.11976 12.6467H5.27309C5.65976 12.6467 6.14643 12.8267 6.43976 13.08L7.49309 13.9867C7.76643 14.22 8.24643 14.22 8.51976 13.9867L9.57309 13.08C9.86643 12.8333 10.3531 12.6467 10.7398 12.6467H11.8731C12.3064 12.6467 12.6598 12.2933 12.6598 11.86V10.7267C12.6598 10.34 12.8398 9.85334 13.0931 9.56L13.9998 8.50667C14.2331 8.23334 14.2331 7.75334 13.9998 7.48L13.0931 6.42667C12.8398 6.13334 12.6598 5.64667 12.6598 5.26V4.13334C12.6598 3.7 12.3064 3.34667 11.8731 3.34667H10.7398C10.3531 3.34667 9.86643 3.16667 9.57309 2.91334L8.51976 2.00667C8.24643 1.77334 7.76643 1.77334 7.49309 2.00667L6.43976 2.92C6.14643 3.16667 5.65309 3.34667 5.27309 3.34667H4.11976Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default ActivateIcon;
