/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {  useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SharedAntdTable from '../../common/shared-antd-table/SharedAntdTable';
import sharedRouterLinks from '../../components/app/shared-routes/sharedRouterLinks';
import UserContext from '../../contexts/user-context/UserProvider';
import queryString from 'query-string';
import  WebsiteRequestsTableColumns from './WebsiteRequestsTableColumns';

const WebsiteRequestsPageTable = () => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const fetchedTableData = useSelector(
    (store) => store.websiteRequests.fetchedTableData
  );
  const tablePagination = useSelector(
    (store) => store.websiteRequests.tablePagination
  );

  const isLoadingTableData = useSelector(
    (store) => store.websiteRequests.isLoadingTableData
  );

  // map all data to dataSource
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  useEffect(() => {
    let isMounted = true;

    if (fetchedTableData?.length >= 0) {
      const mappedData = mapDataSource(fetchedTableData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchedTableData]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <SharedAntdTable
      scroll={1200}
      showHeader={true}
      className={`recent-clients-table ${i18n.dir()}`}
      hasPagination={true}
      tableDataSource={tableDataSource}
      tableColumns={ WebsiteRequestsTableColumns}
      allFetchedTableData={fetchedTableData}
      
      isLoadingTableData={isLoadingTableData}
      user={user}
      tablePagination={tablePagination}
      baseTablePageUrl={`${sharedRouterLinks?.websiteRequests}?searchText=${
        values?.searchText || ''
      }&sort=${values?.sort || ''}`}
    />
  );
};

export default WebsiteRequestsPageTable;
