import axios from 'axios';
import apisUrls from '../urls';

const updatePaymentTypeApi = (values) => {
  const url = apisUrls.updatePaymentType;
  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
export const createPaymentTypeApi = (values) => {
  const url = apisUrls.createPaymentType;
  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default updatePaymentTypeApi;
