/* eslint-disable react-hooks/exhaustive-deps */
import { useQuill } from 'react-quilljs';
import { useEffect, useState } from 'react';
import CustomQuill from './CustomQuill';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getGeneralSettingsApi from '../../apis/general/generalSettingsApi';
import { useTranslation } from 'react-i18next';
import checkRes from '../../utils/checkRes';
import { LoadingOutlined } from '@ant-design/icons';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import updateGeneralSettingsApi from '../../apis/general/updateGeneralSettingsApi';
import imagesSrcs from '../../helpers/imagesSrcs';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import './SettingsPage.scss';
import 'quill/dist/quill.snow.css'; // Add css for snow theme

const SettingsPage = () => {
  const { quill: termsQuillAr, quillRef: termsQuillRefAr } = useQuill();
  const { quill: termsQuillEn, quillRef: termsQuillRefEn } = useQuill();
  const { quill: privacyQuillAr, quillRef: privacyQuillRefAr } = useQuill();
  const { quill: privacyQuillEn, quillRef: privacyQuillRefEn } = useQuill();
  const { quill: paymentQuillEn, quillRef: paymentQuillRefEn } = useQuill();
  const { quill: paymentQuillAr, quillRef: paymentQuillRefAr } = useQuill();

  const [settings, setSettings] = useState({
    terms_en: '',
    terms_ar: '',
    privacy_en: '',
    privacy_ar: '',
    payment_ar: '',
    payment_en: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleQuillChange = (v, name) => {
    setSettings((prev) => {
      return {
        ...prev,
        [name]: v
      };
    });
  };

  const resetSettings = () => {
    setSettings((prev) => {
      return {
        terms_en: '',
        terms_ar: '',
        privacy_en: '',
        privacy_ar: '',
        payment_ar: '',
        payment_en: ''
      };
    });
  };

  const [isLoading, setIsLoading] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [fetchedSettings, setFetchedSettings] = useState(null);
  const customApiRequest = useCustomApiRequest();
  const { i18n } = useTranslation();
  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    customApiRequest(
      getGeneralSettingsApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedSettings(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language]);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const formData = new FormData();
    settings?.terms_en && formData.append('terms_en', settings.terms_en);
    settings?.terms_ar && formData.append('terms_ar', settings.terms_ar);
    settings?.privacy_en && formData.append('privacy_en', settings.privacy_en);
    settings?.privacy_ar && formData.append('privacy_ar', settings.privacy_ar);
    settings?.payment_ar && formData.append('payment_ar', settings.payment_ar);
    settings?.payment_en && formData.append('payment_en', settings.payment_en);
    formData.append('setting_id', 1);
    customApiRequest(
      updateGeneralSettingsApi(formData),
      (res) => {
        setIsSubmitting(false);
        window.scroll(0, 0);
        if (checkRes(res)) {
          setFetchCount((prev) => prev + 1);
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  if (isLoading) {
    return (
      <div className="settings-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }

  return (
    <div className="settings-page">
      <div className="page-title">
        <h3>الإعدادات</h3>
        <h4>الإعدادات الخاصة بنظام Mycash</h4>
      </div>
      <div className="editors-wrapper">
        <div className="row-wrapper">
          <CustomQuill
            initialValue={fetchedSettings?.terms_en || ''}
            label={
              <>
                <img src={imagesSrcs?.termsIcon} alt="terms" />
                الشروط والاحكام بالعربية
              </>
            }
            quillRef={termsQuillRefAr}
            quill={termsQuillAr}
            onChange={(v) => {
              handleQuillChange(v, 'terms_en');
            }}
          />
          <CustomQuill
            initialValue={fetchedSettings?.terms_ar || ''}
            label={
              <>
                <img src={imagesSrcs?.termsIcon} alt="terms" />
                الشروط والاحكام بالانجليزية
              </>
            }
            quillRef={termsQuillRefEn}
            quill={termsQuillEn}
            onChange={(v) => {
              handleQuillChange(v, 'terms_ar');
            }}
          />
        </div>
        <div className="row-wrapper">
          <CustomQuill
            initialValue={fetchedSettings?.privacy_ar || ''}
            label={
              <>
                <img src={imagesSrcs?.privacyIcon} alt="policy" />
                سياسة الخصوصية بالعربية
              </>
            }
            quillRef={privacyQuillRefEn}
            quill={privacyQuillEn}
            onChange={(v) => {
              handleQuillChange(v, 'privacy_ar');
            }}
          />
          <CustomQuill
            initialValue={fetchedSettings?.privacy_en || ''}
            label={
              <>
                <img src={imagesSrcs?.privacyIcon} alt="policy" />
                سياسة الخصوصية بالانجليزية
              </>
            }
            quillRef={privacyQuillRefAr}
            quill={privacyQuillAr}
            onChange={(v) => {
              handleQuillChange(v, 'privacy_en');
            }}
          />
        </div>
        <div className="row-wrapper">
          <CustomQuill
            initialValue={fetchedSettings?.payment_ar || ''}
            label={
              <>
                <img src={imagesSrcs?.privacyIcon} alt="policy" />
                سياسة الدفع بالعربية
              </>
            }
            quillRef={paymentQuillRefAr}
            quill={paymentQuillAr}
            onChange={(v) => {
              handleQuillChange(v, 'payment_ar');
            }}
          />
          <CustomQuill
            initialValue={fetchedSettings?.payment_en || ''}
            label={
              <>
                <img src={imagesSrcs?.privacyIcon} alt="policy" />
                سياسة الدفع بالانجليزية
              </>
            }
            quillRef={paymentQuillRefEn}
            quill={paymentQuillEn}
            onChange={(v) => {
              handleQuillChange(v, 'payment_en');
            }}
          />
        </div>
      </div>

      <div
        className="modal-action-btns-wrapper"
        style={{
          justifyContent: 'end'
        }}
      >
        <button
          className="accept-btn"
          onClick={() => {
            handleSubmit();
          }}
        >
          <CheckmarkCircledIcon />
          حفظ
        </button>
        <button
          onClick={() => {
            resetSettings();
          }}
          className="cancel-btn"
        >
          <CancelCircledIcon color="#404041" />
          إلغاء
        </button>
      </div>

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default SettingsPage;
