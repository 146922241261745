import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createWebsiteExperienceAction,
  deleteWebsiteExperienceAction,
  deleteWebsiteExperienceImageAction,
  getSingleWebsiteExperienceAction,
  getWebsiteExperiencesAction,
  updateWebsiteExperienceAction
} from './websiteExperiencesSliceThunkApi';

const initialState = {
  /////////////////////
  fetchedSingleExperience: null,
  isLoadingTableData: false,
  fetchedTableData: [],
  fetchDataCount: 0,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  confirmModal: {
    opened: false,
    type: ''
  }
};

const websiteExperiencesSlice = createSlice({
  name: 'websiteExperiencesSlice',
  initialState,
  reducers: {
    setFetchDataCount: (sliceState, action) => {
      sliceState.fetchDataCount = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setConfirmModal: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    }
  },
  extraReducers: {
    // get exp
    [getWebsiteExperiencesAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getWebsiteExperiencesAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getWebsiteExperiencesAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    // get single exp
    [getSingleWebsiteExperienceAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getSingleWebsiteExperienceAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSingleExperience = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getSingleWebsiteExperienceAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    //
    // create exp
    [createWebsiteExperienceAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createWebsiteExperienceAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [createWebsiteExperienceAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // update exp
    [updateWebsiteExperienceAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateWebsiteExperienceAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [updateWebsiteExperienceAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    // delete exp
    [deleteWebsiteExperienceAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteWebsiteExperienceAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteWebsiteExperienceAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // delete exp img
    [deleteWebsiteExperienceImageAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteWebsiteExperienceImageAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteWebsiteExperienceImageAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const websiteExperiencesReducer = websiteExperiencesSlice.reducer;

export const {
  setPostFormModalOpened,
  setFetchDataCount,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setSelectedTab
} = websiteExperiencesSlice.actions;

export default websiteExperiencesReducer;
