import axios from 'axios';
import apisUrls from '../urls';

export const deleteWebsiteContactApi = (values) => {
  return axios.post(apisUrls?.deleteSingleWebsiteContactUrl, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getAllWebsiteContactsApi = (filterObj) => {
  return axios.get(apisUrls?.getWebsiteContactsUrl(filterObj));
};

export const getSingleWebsiteContactApi = (filterObj) => {
  return axios.get(apisUrls?.getSingleWebsiteContactUrl(filterObj));
};
