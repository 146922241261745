/* eslint-disable react-hooks/exhaustive-deps */
import './ClientsPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import { AnimatePresence, motion } from 'framer-motion';
import ClientForm from './ClientForm';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getPackagesAction } from '../../redux/packages-slice/appPackagesSliceThunkApi';
import {
  changeClientStatusAction,
  deleteClientAction,
  getClientsAction
} from '../../redux/clients-slice/clientsSliceThunkApi';
import {
  setConfirmModal,
  setDeleteModalOpened
} from '../../redux/clients-slice/clientsSlice';
import ClientsPageFilter from './ClientsPageFilter';
import ClientsPageTable from './ClientsPageTable';
import PackageDescription from './PackageDescription';
import { setSelectedTableRow } from '../../redux/clients-slice/clientsSlice';
import { getDevicesAction } from '../../redux/devices-slice/devicesSliceThunkApis';

const ClientsPage = () => {
  const dispatch = useDispatch();
  const deleteModalOpened = useSelector(
    (store) => store.clients.deleteModalOpened
  );
  const opened = useSelector((store) => store.clients.confirmModal.opened);
  const type = useSelector((store) => store.clients.confirmModal.type);
  const isLoadingTableData = useSelector(
    (store) => store.clients.isLoadingTableData
  );
  const selectedTableRow = useSelector(
    (store) => store.clients.selectedTableRow
  );
  const postFormModalOpened = useSelector(
    (store) => store.clients.postFormModalOpened
  );
  const watchClientPackage = useSelector(
    (store) => store.clients.watchClientPackage
  );
  const tablePagination = useSelector((store) => store.clients.tablePagination);
  const fetchDataCount = useSelector((store) => store.clients.fetchDataCount);
  const isSubmitting = useSelector((store) => store.clients.isSubmitting);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const fetchedTableData = useSelector(
    (store) => store.clients.fetchedTableData
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let isMounted = true;
  useEffect(() => {
    if (isMounted) {
      dispatch(
        getPackagesAction({
          filterObj: null
        })
      );
      dispatch(getDevicesAction());
    }

    return function () {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted)
      dispatch(
        getClientsAction({
          filterObj: values
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, search]);

  const handleDeleteClient = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('user_id', selectedTableRow.id);
      dispatch(
        deleteClientAction({
          dataToBeSubmitted: formData,
          isClientPage: true
        })
      );
    }
  };

  const handleChangeClientStatus = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('client_id', selectedTableRow.id);
      dispatch(
        changeClientStatusAction({
          dataToBeSubmitted: formData,
          isClientPage: true
        })
      );
    }
  };

  // if (isLoadingTableData) {
  //   return (
  //     <div className="clients-page">
  //       <div
  //         style={{
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           minHeight: 360
  //         }}
  //         className="loading-wrapper"
  //       >
  //         <LoadingOutlined style={{ fontSize: 24 }} spin />
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="clients-page">
      <div
        className={`page-content-devices-wrapper ${
          postFormModalOpened ? 'with-side-content' : ''
        }`}
      >
        <AnimatePresence>
          {!postFormModalOpened && (
            <motion.div
              // layout
              initial={{
                height: 0,
                opacity: 0,
                translateX: 1400,
                transition: {
                  opacity: { ease: 'linear', duration: 0.4 },
                  translateX: { ease: 'linear', duration: 0.4 }
                }
              }}
              animate={{
                height: 'auto',
                opacity: 1,
                translateX: 0,
                transition: {
                  delay: 0.7,
                  opacity: { ease: 'linear', duration: 0.4 },
                  translateX: { ease: 'linear', duration: 0.4 }
                }
              }}
              exit={{
                opacity: 0,
                translateX: 700,
                transition: {
                  opacity: { ease: 'linear', duration: 0.4 },
                  translateX: { ease: 'linear', duration: 0.4 }
                }
              }}
              className="page-main-content"
            >
              <div className="page-title">العملاء</div>
              <ClientsPageFilter />
              <ClientsPageTable
                fetchedTableData={fetchedTableData}
                tablePagination={tablePagination}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <div className="client-form-package-wrapper">
          {/* Client FORM */}
          <AnimatePresence>
            {postFormModalOpened && (
              <motion.div
                initial={{
                  opacity: 0,
                  translateX: 700,
                  height: 0
                }}
                animate={{
                  height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.9,
                    opacity: { ease: 'linear', duration: 0.4 },
                    translateX: { ease: 'linear', duration: 0.4 }
                  }
                }}
                exit={{
                  opacity: 0,
                  translateX: 700,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.4 },
                    translateX: { ease: 'linear', duration: 0.4 }
                  }
                }}
                // transition={{
                //   delay: 0.7,
                //   opacity: { ease: 'linear', duration:.4 },
                //   width: { ease: 'linear', duration:.4 },
                //   translateX: { ease: 'linear', duration:.4 }
                // }}
                className="device-form-wrapper"
              >
                <ClientForm />
              </motion.div>
            )}
          </AnimatePresence>
          {/* End Client form */}

          {/* PAGE SIDE CONTENT */}

          {/* DEVICES LIST */}
          <AnimatePresence>
            {postFormModalOpened && (
              <motion.div
                className="desc-wrapper"
                initial={{
                  opacity: 0,
                  translateX: '-700px',
                  transition: {
                    opacity: { ease: 'linear', duration: 0.4 },
                    translateX: { ease: 'linear', duration: 0.4 }
                  }
                }}
                animate={{
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.7,
                    opacity: { ease: 'linear', duration: 0.4 },
                    translateX: { ease: 'linear', duration: 0.4 }
                  }
                }}
                exit={{
                  opacity: 0,
                  translateX: '-700px',
                  transition: {
                    opacity: { ease: 'linear', duration: 0.4 },
                    translateX: { ease: 'linear', duration: 0.4 }
                  }
                }}
              >
                <PackageDescription item={watchClientPackage} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* END PAGE SIDE CONTENT */}
      </div>

      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}

      {/* confirm clients modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={opened}
        modalType={type}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedTableRow(null));
          }
          dispatch(
            setConfirmModal({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleChangeClientStatus}
      >
        {type === confirmModalTypesValues?.successModal && (
          <h3>{`هل انت متأكد من قبول العميل  ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
        {type === confirmModalTypesValues?.errorModal && (
          <h3>{`هل انت متأكد من رفض العميل  ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
      </ConfirmModal>
      {/* end confirm clients modal */}

      {/* delete client modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => dispatch(setDeleteModalOpened(v))}
        handleAccept={handleDeleteClient}
      >
        <h3>{`هل انت متأكد من حذف العميل ${selectedTableRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* end delete client modal */}

      {(isSubmitting || isLoadingTableData) && <LoadingModal />}
    </div>
  );
};

export default ClientsPage;
