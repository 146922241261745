const checkSuccessResponse = (res) => {
  // return res?.status === 200 && res?.data?.status === 1;
  return (
    res?.status === 200
    // res?.status === 200 && res?.data?.status === 1
  );
};

const checkUnAuthenticated = (err) => {
  return err?.response?.status === 401;
};

const customApiRequest = ({
  apiCall,
  successCallback,
  errorCallback,
  thunkAPI,
  ...props
}) => {
  // console.log('api call : ', apiCall);
  try {
    return {
      res: {
        data: apiCall?.data,
        status: apiCall?.status,
        statusText: apiCall?.statusText
      },
      ...props
    };
  } catch (error) {
    thunkAPI.rejectWithValue(
      error?.response?.data?.message || 'حاول فى وقت لاحق'
    );
  }
};

export default customApiRequest;
