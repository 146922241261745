/* eslint-disable react-hooks/rules-of-hooks */
import { Tag } from 'antd';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import CustomImage from '../../common/custom-image/CustomImage';
import CustomLoader from '../../common/custom-loader/CustomLoader';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import {
  brandsStatus,
  getBrandsStatusArr
} from '../../const-values/brandsStatus';
import getFoundObjByValue from '../../helpers/getFoundObjByValue';
import {
  setBrandFormOpenedAction,
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/brands-slice/brandsSlice';

const BrandsTable = ({ fetchedBrands }) => {
  const dispatch = useDispatch();
  const isLoadingTableData = useSelector(
    (store) => store.brands.isLoadingTableData
  );

  const BrandItem = ({ id, image, name, name_ar, name_en, status }) => {
    return (
      <div className="device-item">
        <CustomImage src={image} className="device-img-wrapper" />
        <div className="item-content">
          <div
            className="device-details"
            style={{
              display: 'flex',
              gap: 4,
              alignItems: 'center'
            }}
          >
            <p className="name">{name}</p>
          </div>
          <div className="item-btns-wrapper">
            <button
              onClick={() => {
                dispatch(
                  setSelectedTableRow({
                    id,
                    image,
                    name,
                    name_ar,
                    name_en,
                    status
                  })
                );
                dispatch(setDeleteModalOpened(true));
              }}
              style={{
                padding: 0
              }}
              className="delete-btn"
            >
              <TrashIcon color="rgba(64, 64, 65, 0.6)" />
              حذف
            </button>
            <button
              style={{
                padding: 0
              }}
              className="edit-btn"
              onClick={() => {
                dispatch(
                  setSelectedTableRow({
                    image,
                    name_ar,
                    name_en,
                    status,
                    id
                  })
                );
                dispatch(setBrandFormOpenedAction(true));
              }}
            >
              <EditIcon color="rgba(64, 64, 65, 0.6)" />
              تعديل
            </button>

            {getFoundObjByValue(getBrandsStatusArr(), status) && (
              <Tag color={status === brandsStatus?.active ? 'green' : 'red'}>
                {getFoundObjByValue(getBrandsStatusArr(), status).name}
              </Tag>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderBrandsList = useMemo(() => {
    return () => {
      return (
        <div className="devices-list-wrapper">
          {fetchedBrands?.data?.map((item) => {
            return <BrandItem key={item.id} image={item?.icon} {...item} />;
          })}
        </div>
      );
    };
  }, [fetchedBrands]);

  if (isLoadingTableData) {
    return <CustomLoader />;
  } else if (!fetchedBrands || fetchedBrands?.data?.length === 0) {
    return (
      <div className="empty-devices-wrapper">
        <CustomEmpty>
          <p>قم بإضافة البراندات هنا</p>
        </CustomEmpty>
      </div>
    );
  } else if (fetchedBrands?.data?.length > 0) return renderBrandsList();
  return null;
};

export default BrandsTable;
