import axios from 'axios';

const getGeneralSettingsApi = async (langIsoCode) => {
  try {
    const url = '/setting/single?setting_id=1';
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        lang: langIsoCode
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getGeneralSettingsApi;
