import axios from 'axios';

const updateGeneralSettingsApi = async (values, langIsoCode) => {
  try {
    const url = '/setting/update';
    const res = await axios.post(url, values, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default updateGeneralSettingsApi;
