/* eslint-disable react-hooks/exhaustive-deps */
import './InfluencersPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import { AnimatePresence, motion } from 'framer-motion';
import PackageForm from './InfluencerForm';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  deleteInfluencerAction,
  getInfluencersAction,
  getInfluencersSocialsAction
} from '../../redux/influcencers-slice/influencersSliceThunkApi';
import InfluencersPageFilter from './InfluencersPageFilter';
import InfluencersPageTable from './InfluencersPageTable';
import {
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/influcencers-slice/influencersSlice';

const InfluencersPage = () => {
  const dispatch = useDispatch();
  const deleteModalOpened = useSelector(
    (store) => store.influencers.deleteModalOpened
  );
  const isLoadingTableData = useSelector(
    (store) => store.influencers.isLoadingTableData
  );
  const selectedTableRow = useSelector(
    (store) => store.influencers.selectedTableRow
  );
  const postFormModalOpened = useSelector(
    (store) => store.influencers.postFormModalOpened
  );
  const fetchDataCount = useSelector(
    (store) => store.influencers.fetchDataCount
  );
  const isSubmitting = useSelector((store) => store.influencers.isSubmitting);
  const { search } = useLocation();
  const values = queryString.parse(search);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let isMounted = true;
  useEffect(() => {
    if (isMounted)
      // dispatch(
      //   getInfluencersSocialsAction({
      //     filterObj: null
      //   })
      // );

    return function () {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted)
      dispatch(
        getInfluencersAction({
          filterObj: values
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, search]);

  const handleDeleteInfluencer = () => {
    if (isMounted && selectedTableRow?.id) {
  
      dispatch(
        deleteInfluencerAction({
          dataToBeSubmitted:  selectedTableRow.id
        })
      );
    }
  };

  // if (isLoadingTableData) {
  //   return (
  //     <div className="influencers-page">
  //       <div
  //         style={{
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           minHeight: 360
  //         }}
  //         className="loading-wrapper"
  //       >
  //         <LoadingOutlined style={{ fontSize: 24 }} spin />
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="influencers-page">
      <div className={`page-content-influencers-wrapper`}>
        <div>
          <AnimatePresence>
            {!postFormModalOpened && (
              <motion.div
                // layout
                initial={{
                  height: 0,
                  opacity: 0,
                  translateX: 700,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.3 },
                    translateX: { ease: 'linear', duration: 0.3 }
                  }
                }}
                animate={{
                  height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.7,
                    opacity: { ease: 'linear', duration: 0.3 },
                    translateX: { ease: 'linear', duration: 0.3 }
                  }
                }}
                exit={{
                  opacity: 0,
                  translateX: 700,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.3 },
                    translateX: { ease: 'linear', duration: 0.3 }
                  }
                }}
                className="page-main-content"
              >
                <div className="page-title">المسوقين</div>
                <InfluencersPageFilter />
                <InfluencersPageTable />
              </motion.div>
            )}
          </AnimatePresence>

          {/* PACKAGE FORM */}
          <AnimatePresence>
            {postFormModalOpened && (
              <motion.div
                initial={{
                  // height: 0,
                  opacity: 0,
                  translateX: 700
                }}
                animate={{
                  // height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.6,
                    opacity: { ease: 'linear', duration: 0.3 },
                    translateX: { ease: 'linear', duration: 0.3 }
                  }
                }}
                exit={{
                  opacity: 0,
                  translateX: 400,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.3 },
                    translateX: { ease: 'linear', duration: 0.3 }
                  }
                }}
                // transition={{
                //   delay: 0.7,
                //   opacity: { ease: 'linear', duration: .3 },
                //   width: { ease: 'linear', duration: .3 },
                //   translateX: { ease: 'linear', duration: .3 }
                // }}
                className="device-form-wrapper"
              >
                <PackageForm />
              </motion.div>
            )}
          </AnimatePresence>
          {/* End package form */}
        </div>
      </div>

      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}

      {/* delete package modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedTableRow(null));
          }
          dispatch(setDeleteModalOpened(v));
        }}
        handleAccept={handleDeleteInfluencer}
      >
        <h3>{`هل انت متأكد من حذف المسوق ${selectedTableRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* end delete package modal */}

      {(isSubmitting || isLoadingTableData) && <LoadingModal />}
    </div>
  );
};

export default InfluencersPage;
