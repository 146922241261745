import PlusIcon from '../icons/PlusIcon';
import './CreateNewBtn.scss';

const CreateNewBtn = ({ btnText, onClick, ...props }) => {
  return (
    <button
      {...props}
      onClick={() => {
        if (onClick && typeof onClick === 'function') {
          onClick();
        }
      }}
      className="shared-create-new-btn"
    >
      <PlusIcon />
      {btnText}
    </button>
  );
};

export default CreateNewBtn;
