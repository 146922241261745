import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createAppGuideApi,
  deleteAppGuideApi,
  getAllAppGuideApi
} from '../../apis/app-guide-apis/appGuideApis';
import customApiRequest from '../../helpers/customApiRequest';

export const getAppGuideAction = createAsyncThunk(
  'appGuide/getAppGuideAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllAppGuideApi(filterObj),
      thunkAPI
    });
  }
);

export const getSingleAppGuideAction = createAsyncThunk(
  'appGuide/getSingleAppGuideAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSingleAppGuideAction(filterObj),
      thunkAPI
    });
  }
);

// create website guide
export const createAppGuideAction = createAsyncThunk(
  'appGuide/createAppGuideAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createAppGuideApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//
// update website guide
export const updateAppGuideAction = createAsyncThunk(
  'appGuide/updateAppGuideAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createAppGuideApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);
//

export const deleteAppGuideAction = createAsyncThunk(
  'appGuide/deleteAppGuideAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteAppGuideApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
