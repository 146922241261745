import './StatisticsBoxs.scss';

const StatisticsBoxs = ({ obj }) => {
  const boxsArr = [
    {
      id: 1,
      title: 'زوار الموقع الالكترونى',
      subTitle: 'أخر تحديث اليوم',
      value: obj?.visitors || '0'
    },
    {
      id: 2,
      title: 'عملاء تطبيق mycash',
      subTitle: 'أخر تحديث اليوم',
      value: obj?.usersCount || '-'
    },
    {
      id: 3,
      title: 'كوبونات الخصم',
      subTitle: 'أخر تحديث اليوم',
      value: obj?.coupons || '-'
    },
    {
      id: 4,
      title: 'العمليات المؤرشفة',
      subTitle: 'أخر تحديث اليوم',
      value: obj?.archiveCount || '-'
    }
  ];

  const Box = ({ title, subTitle, value }) => {
    return (
      <div className="box-wrapper">
        <div className="box-value">{value}</div>
        <div className="box-text">
          <p className="title-p">{title}</p>
          <p className="sub-title-p">{subTitle}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="stats-boxs-wrapper">
      {boxsArr.map((item) => (
        <Box key={item?.id} {...item} />
      ))}
    </div>
  );
};

export default StatisticsBoxs;
