/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { Button, Drawer, Dropdown, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  useLocation,
  Link as RouterLink,
  Link
} from 'react-router-dom';
import LanguageButton from '../../common/language-button/LanguageButton';
import Logo from '../../common/logo/Logo';
import mainAppBarLinks from './mainAppBarLinks';
import './MainAppBarMd.scss';
import MainAppBarContext from '../../contexts/main-app-bar-context/MainAppBarProvider';

import slugify from 'slugify';
import MainAppBarSideMenu from './MainAppBarSubMenu';

const MainAppBarMd = ({ className, exceeds0 }) => {
  const { i18n } = useTranslation();
  const { menuMdOpened, setMenuMdOpened } = useContext(MainAppBarContext);
  const { pathname } = useLocation();
  const { links, setLinks, setSelectedLink, activeLink } =
    useContext(MainAppBarContext);

  const handleActiveLink = (to, visibleSubMenu) => {
    let isActive = false;
    if (pathname && to) {
      if (pathname === to) {
        isActive = true;
      } else if (visibleSubMenu) {
        isActive = true;
      } else if (activeLink && to && slugify(activeLink?.to) == slugify(to)) {
        isActive = true;
      }
    }
    return isActive;
  };

  const handleMenuBtnClick = (id) => {
    setLinks((prevState) => {
      return prevState.map((menu) =>
        menu.id === id
          ? { ...menu, visibleSubMenu: true }
          : { ...menu, visibleSubMenu: false }
      );
    });

    const foundLink = links.find((link) => link.id === id);
    if (foundLink) setSelectedLink({ ...foundLink, visibleSubMenu: true });

    setMenuMdOpened(false);
  };

  useEffect(() => {
    if (menuMdOpened) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [menuMdOpened]);

  const renderMainLinks = () => {
    return (
      <ul className="app-bar-ul">
        {links.map((item) => {
          const {
            id,
            isLink,
            to,
            name,
            icon,
            visibleSubMenu,
            subMenu,
            isSeparator
          } = item;
          return isLink ? (
            <RouterLink
              to={to}
              className="link-li"
              key={id}
              onClick={() => handleMenuBtnClick(id)}
            >
              <div
                className={`nav-link ${
                  handleActiveLink(to, visibleSubMenu) ? 'active-link' : ''
                }`}
              >
                {icon && typeof icon === 'function' && (
                  <span
                    className="icon-span"
                    style={{
                      display: 'flex'
                    }}
                  >
                    {icon(
                      handleActiveLink(to, visibleSubMenu) ? '#2a9d7c' : ''
                    )}
                  </span>
                )}
                <span className="name-span">{name}</span>
              </div>
              <MainAppBarSideMenu
                visibleSubMenu={visibleSubMenu}
                name={id}
                menuItems={subMenu}
              />
            </RouterLink>
          ) : (
            <li
              className="link-li"
              key={id}
              onClick={() => handleMenuBtnClick(id)}
            >
              <div
                className={`nav-link
                ${slugify(pathname) === slugify(to) ? 'active-link' : ''}
                ${visibleSubMenu ? 'active-link' : ''}
                ${
                  activeLink && slugify(activeLink?.to) === slugify(to)
                    ? 'active-link'
                    : ''
                }
                `}
                // to={path}
              >
                {icon && typeof icon === 'function' && (
                  <span
                    className="icon-span"
                    style={{
                      display: 'flex'
                    }}
                  >
                    {icon(
                      slugify(pathname) === slugify(to) ||
                        visibleSubMenu ||
                        (activeLink && slugify(activeLink?.to) === slugify(to))
                        ? '#fff'
                        : ''
                    )}
                  </span>
                )}
                <span
                  className={isSeparator ? 'name-span separator' : 'name-span'}
                >
                  {name}
                </span>
              </div>
              {subMenu?.length > 0 && (
                <MainAppBarSideMenu
                  visibleSubMenu={visibleSubMenu}
                  name={id}
                  menuItems={subMenu}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Drawer
      className="md-drawer"
      title={
        <div className="md-logo">
          <Logo />
        </div>
      }
      placement={i18n?.dir() === 'rtl' ? 'right' : 'left'}
      closable={false}
      onClose={() => {
        setMenuMdOpened(false);
      }}
      open={menuMdOpened}
    >
      {renderMainLinks()}
    </Drawer>
  );
};

export default MainAppBarMd;
