/* eslint-disable react-hooks/exhaustive-deps */
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  PDFViewer,
  Font
} from '@react-pdf/renderer';
import { Modal } from 'antd';
import qrImg from '../../assets/imgs/qr.png';
// ../assets/fonts/Poppins-Regular.woff2) format('woff2'
// import fontSrc from '../../../assets/fonts/Cairo-Regular.ttf';
import CairoRegular from '../../assets/fonts/Cairo-Regular.ttf';
import CairoBold from '../../assets/fonts/Cairo-Bold.ttf';
import './InfluencerInvoiceModal.scss';

Font.register({
  // family: 'PoppinsRegular',
  // src: fontSrc

  family: 'Cairo-Regular',
  fonts: [
    {
      src: CairoRegular,
      fontWeight: 'normal'
    },
    {
      src: CairoBold,
      fontWeight: 'bold'
    }
  ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    // margin: 0,
    backgroundColor: '#fff',
    fontFamily: 'Cairo-Regular'
  }
});

const InfluencerInvoiceModal = ({
  modalOpened,
  setModalOpened,
  modalData,
  parentInfo
}) => {
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row-reverse',
            padding: '22 0',
            marginBottom: 22,
            justifyContent: 'space-between',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: '#bbb'
          }}
        >
          <View
            style={{
              width: '32%',
              height: 93,
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              backgroundColor: '#404041',
              borderTopLeftRadius: 999,
              borderBottomLeftRadius: 999
            }}
          >
            <Image
              style={{
                width: 94
              }}
              src="/assets/imgs/logo/logo.png"
              alt="sdf"
            />
          </View>

          <View
            style={{
              width: '64%',
              padding: '0 22'
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 18,
                color: '#404041'
              }}
            >
              <Text
                style={{
                  marginRight: 4
                }}
              >
                <Text>#</Text>
                <Text>{modalData.id}</Text>
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: 'bold'
                }}
              >
                فاتورة ضريبية
              </Text>
            </View>

            <View
              style={{
                fontSize: 12,
                color: '#404041'
              }}
            >
              <Text>المملكة العربية السعودية</Text>
              <Text>المدينة المنورة</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Text
                  style={{
                    marginRight: 4
                  }}
                >
                  رقم ضريبة القيمة المضافة
                </Text>
                <Text>012255488256315</Text>
              </View>
            </View>
          </View>
        </View>

        {/*  */}
        {/*  */}
        <View
          style={{
            padding: '0 22',
            color: '#000000',
            fontSize: 12,
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between'
          }}
        >
          <View
            style={{
              width: '45%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <View
              style={{
                marginRight: 4
              }}
            >
              <Text>{parentInfo?.email}</Text>
              <Text>{parentInfo?.phone}</Text>
            </View>
            <View>
              <Text>فاتورة إلى</Text>
            </View>
          </View>
          {/*  */}
          <View
            style={{
              width: '45%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              textAlign: 'right'
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
                marginBottom: 4
              }}
            >
              <Text>رقم الفاتورة</Text>
              <Text>{modalData?.id}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
                marginBottom: 4
              }}
            >
              <Text>تاريخ الاصدار</Text>
              <Text>{modalData?.date || '--'}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse'
              }}
            >
              <Text>الوقت</Text>
              <Text>{modalData?.time || '--'}</Text>
            </View>
          </View>
          {/*  */}
        </View>
        {/*  */}
        {/*  */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: '#bbb'
          }}
        >
          <View
            style={{
              marginTop: 12,
              padding: '12 22',
              fontSize: 12,
              backgroundColor: '#404041',
              color: '#fff',
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between'
            }}
          >
            <Text
              style={{
                width: '48%',
                textAlign: 'right'
              }}
            >
              البيان
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                width: '52%'
              }}
            >
              <Text
                style={{
                  width: '25%',
                  padding: '0 4',
                  textAlign: 'center'
                }}
              >
                السعر
              </Text>
              <Text
                style={{
                  width: '20%',
                  padding: '0 4',
                  textAlign: 'center'
                }}
              >
                الخصم
              </Text>
              <Text
                style={{
                  width: '20%',
                  padding: '0 4',
                  textAlign: 'center'
                }}
              >
                الكمية
              </Text>
              <Text
                style={{
                  width: '35%',
                  padding: '0 4',
                  textAlign: 'left'
                }}
              >
                الاجمالى
              </Text>
            </View>
          </View>
          <View
            style={{
              padding: '12 22',
              fontSize: 12,
              color: '#000',
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between'
            }}
          >
            <View
              style={{
                width: '48%',
                textAlign: 'right'
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  fontWeight: 'bold'
                }}
              >
                <Text>باقة</Text>
                <Text
                  style={{
                    margin: '0 2'
                  }}
                >
                  {modalData?.package?.price}
                </Text>
                <Text>ر.س</Text>
              </View>
              {modalData?.package?.duration && (
                <Text
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 12
                  }}
                >
                  <Text>Mycash</Text>
                  <Text>لتطبيق</Text>
                  <Text> {modalData?.package?.duration} </Text>
                  <Text>إشتراك</Text>
                </Text>
              )}
            </View>
            <View
              style={{
                width: '52%',
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Text
                style={{
                  width: '25%',
                  padding: '0 4',
                  textAlign: 'center'
                }}
              >
                {modalData?.price}
              </Text>
              <Text
                style={{
                  width: '20%',
                  padding: '0 4',
                  textAlign: 'center'
                }}
              >
                {modalData?.discountPrice}
              </Text>
              <Text
                style={{
                  width: '20%',
                  padding: '0 4',
                  textAlign: 'center'
                }}
              >
                {modalData?.quantity}
              </Text>
              <View
                style={{
                  width: '35%',
                  padding: '0 4',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'flex-end',
                  textAlign: 'left'
                }}
              >
                <Text>{modalData?.totalPrice}</Text>
                <Text
                  style={{
                    marginRight: 2
                  }}
                >
                  ر.س
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/*  */}
        {/*  */}
        {/*  */}
        {/* Invoice body */}
        <View
          style={{
            color: '#000',
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            padding: '12 22',
            marginBottom: 92
          }}
        >
          <View
            style={{
              width: '45%'
            }}
          ></View>
          <View
            style={{
              width: '52%',
              fontSize: 12
            }}
          >
            {/* row */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between'
              }}
            >
              <Text
                style={{
                  width: '78%',
                  textAlign: 'right'
                }}
              >
                إجمالى المبلغ )غير شامل ضريبة القيمة المضافة(
              </Text>
              <Text
                style={{
                  width: '20%'
                }}
              >
                {modalData?.price}
              </Text>
            </View>
            marginBottom: 3,
            {/* row */}
            <View
              style={{
                marginBottom: 3,
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between'
              }}
            >
              <Text
                style={{
                  width: '78%',
                  textAlign: 'right'
                }}
              >
                ضريبة القيمة المضافة
                <Text>(15</Text>
                <Text>%)</Text>
              </Text>
              <Text
                style={{
                  width: '20%'
                }}
              >
                {modalData?.taxPrice}
              </Text>
            </View>
            {/* row */}
            {/* <View
              style={{
                marginBottom: 3,
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between'
              }}
            >
              <Text
                style={{
                  width: '78%',
                  textAlign: 'right'
                }}
              >
                إجمالى المبلغ
              </Text>
              <Text
                style={{
                  width: '20%'
                }}
              >
                1234.5
              </Text>
            </View> */}
            {/* row */}
            <View
              style={{
                marginBottom: 3,
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between'
              }}
            >
              <Text
                style={{
                  width: '78%',
                  textAlign: 'right'
                }}
              >
                الخصم
              </Text>
              <Text
                style={{
                  width: '20%'
                }}
              >
                {modalData?.discountPrice || '-'}
              </Text>
            </View>
            {/* row */}
            <View
              style={{
                marginBottom: 3,
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between'
              }}
            >
              <Text
                style={{
                  width: '78%',
                  textAlign: 'right'
                }}
              >
                طريقة الدفع
              </Text>
              <Text
                style={{
                  width: '20%'
                }}
              >
                نقدا
              </Text>
            </View>
            {/* row */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between'
              }}
            >
              <Text
                style={{
                  width: '50%',
                  textAlign: 'right',
                  fontWeight: 'bold'
                }}
              >
                الإجمالى
              </Text>
              <View
                style={{
                  width: '48%',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'flex-end'
                }}
              >
                <Text>{modalData?.totalPrice} </Text>
                <Text
                  style={{
                    marginRight: 2
                  }}
                >
                  ر.س
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/*  */}
        {/*  */}
        {/*  */}
        {/* Invoice footer  */}
        <View
          style={{
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: '#bbb',
            padding: '0 22',
            paddingTop: 22,
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between'
          }}
        >
          <View
            style={{
              width: '75%',
              display: 'flex',
              alignContent: 'flex-end',
              alignItems: 'flex-end',
              textAlign: 'right',
              fontSize: 10
            }}
          >
            <Image
              style={{
                width: 78
              }}
              src="/assets/imgs/logo/logo-footer.png"
            />
            <Text
              style={{
                marginTop: 4,
                color: '#292B30'
              }}
            >
              هذه الفاتورة هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة{' '}
            </Text>
            <Text
              style={{
                marginTop: 4,
                color: '#292B30',
                fontWeight: 'bold'
              }}
            >
              شكرا لتعاملكم معنا
            </Text>
            <View
              style={{
                color: '#292B30',
                marginTop: 4,
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Text
                style={{
                  marginLeft: 4
                }}
              >
                رقم التسجيل الضريبي
              </Text>
              <Text>012255488256315</Text>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Text>Powered by</Text>
              <Image
                style={{
                  width: 36,
                  height: '12',
                  marginLeft: 4,
                  marginTop: 6
                }}
                src="/assets/imgs/logo/logo-footer.png"
              />
            </View>
          </View>
          <View
            style={{
              width: '20%'
            }}
          >
            <Image
              style={{
                width: 108
              }}
              src={qrImg}
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <Modal
      wrapClassName="invoice-modal-wrapper"
      className="shared-custom-modal invoice-modal"
      width="96%"
      style={{
        maxWidth: '992px',
        top: '2%',
        bottom: '2%',
        height: '96vh',
        maxHeight: '1080px'
      }}
      title=""
      open={modalOpened}
      // onOk={() => {
      //   setModalOpened(false);
      // }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <PDFViewer
        style={{
          // 'transform:scale': '80%',

          width: '100%',
          maxWidth: '992px',
          height: '100%',
          outline: 0,
          borderRadius: 18,
          border: '1px solid rgba(255, 255, 255, 0.2)'
        }}
      >
        <MyDocument />
      </PDFViewer>
      {/* <div className="modal-action-btns-wrapper">
        <button
          className="accept-btn"
          onClick={() => {
            handleAccept();
          }}
        >
          <CheckmarkCircledIcon />
          نعم
        </button>
        <button
          onClick={() => {
            setModalOpened(false);
          }}
          className="cancel-btn"
        >
          <CancelCircledIcon color="#404041" />
          لا
        </button>
      </div> */}
    </Modal>
  );
};

export default InfluencerInvoiceModal;
