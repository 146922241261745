import React from 'react';

const PlusIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M18.9534 12.8742H6.86077C6.44761 12.8742 6.10498 12.5321 6.10498 12.1195C6.10498 11.707 6.44761 11.3648 6.86077 11.3648H18.9534C19.3666 11.3648 19.7092 11.707 19.7092 12.1195C19.7092 12.5321 19.3666 12.8742 18.9534 12.8742Z"
        fill={color || '#2A9D7C'}
      />
      <path
        d="M12.9072 18.9116C12.494 18.9116 12.1514 18.5695 12.1514 18.1569V6.0821C12.1514 5.66954 12.494 5.32742 12.9072 5.32742C13.3203 5.32742 13.6629 5.66954 13.6629 6.0821V18.1569C13.6629 18.5695 13.3203 18.9116 12.9072 18.9116Z"
        fill={color || '#2A9D7C'}
      />
    </svg>
  );
};

export default PlusIcon;
