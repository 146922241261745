import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const DocTitleScrollTop = (docTitle) => {
  const { pathname, search } = useLocation();
  const { i18n } = useTranslation();
  return useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.scrollTo(0, 0);
      document.title = `${docTitle ? `${docTitle} |` : ''} ${
        i18n.language === 'en'
          ? process.env.REACT_APP_TITLE
          : process.env.REACT_APP_TITLE_AR
      }`;
    }
    return () => {
      isMounted = false;
      document.title =
        i18n.language === 'en'
          ? process.env.REACT_APP_TITLE
          : process.env.REACT_APP_TITLE_AR;
    };
  }, [docTitle, pathname, search, i18n.language]);
};

export default DocTitleScrollTop;
