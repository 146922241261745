import { useContext } from 'react';
import accountTypes from '../../../const-values/accountTypes';
import UserContext from '../../../contexts/user-context/UserProvider';
import generalRoute from '../../../utils/generalRoute';
import routerLinks from '../routerLinks';
import generalRouterLinks from './gerneralRouterLinks';

const GeneralRoutes = () => {
  const { user, loggedIn } = useContext(UserContext);
  return [
    generalRoute(
      generalRouterLinks?.singleBlogRoute(),
      <h1>Single blog page</h1>,
      'single_blog_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.merchent, accountTypes()?.employee]
    )

    //
  ];
};

export default GeneralRoutes;
