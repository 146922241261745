import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SharedAntdTable from '../../common/shared-antd-table/SharedAntdTable';
import sharedRouterLinks from '../../components/app/shared-routes/sharedRouterLinks';
import UserContext from '../../contexts/user-context/UserProvider';

import ProfileAdminsTableColumns from './ProfileAdminsTableColumns';
import queryString from 'query-string';
import './ProfileAdminsTable.scss';
import AdminsTableFilter from './AdminsTableFilter';
import {
  setConfirmModal,
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/settings-slice/settingsSlice';

const ProfileAdminsTable = () => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const fetchedTableData = useSelector(
    (store) => store.settings.fetchedTableData
  );
  const fetchDataCount = useSelector((store) => store.settings.fetchDataCount);
  const deleteModalOpened = useSelector(
    (store) => store.settings.deleteModalOpened
  );
  const isLoadingTableData = useSelector(
    (store) => store.settings.isLoadingTableData
  );
  // map all data to dataSource
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  useEffect(() => {
    let isMounted = true;

    if (fetchedTableData?.length >= 0) {
      const mappedData = mapDataSource(fetchedTableData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchDataCount, fetchedTableData]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const tablePagination = useSelector(
    (store) => store.settings.tablePagination
  );

  return (
    <div className="profile-admins-table-wrapper">
      <div className="table-title">قائمة مستخدمي لوحة تحكم mycash</div>

      <AdminsTableFilter />
      <SharedAntdTable
        scroll={1200}
        showHeader={true}
        className={`profile-admins-table ${i18n.dir()}`}
        hasPagination={true}
        tableDataSource={tableDataSource}
        tableColumns={ProfileAdminsTableColumns}
        allFetchedTableData={fetchedTableData}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        deleteModalOpened={deleteModalOpened}
        setDeleteModalOpened={(v) => dispatch(setDeleteModalOpened(v))}
        setSelectedTableRow={(v) => dispatch(setSelectedTableRow(v))}
        //
        setConfirmModal={(v) => dispatch(setConfirmModal(v))}
        isLoadingTableData={isLoadingTableData}
        user={user}
        tablePagination={tablePagination}
        baseTablePageUrl={`${sharedRouterLinks?.profileRoute}?searchText=${
          values?.searchText || ''
        }&sort=${values?.sort || ''}`}
      />
    </div>
  );
};

export default ProfileAdminsTable;
