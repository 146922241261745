import {
  Document,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';
import React from 'react';
import InvoiceMainFooter from './InvoiceMainFooter';
import InvoiceTable from './InvoiceTable';
import { useEffect } from 'react';

const InvoicePdf = ({ data, imageUrl, zakatQr }) => {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#fff',
      fontFamily: 'Cairo-Regular'
    }
  });

  const h1Style = { fontSize: '20px', fontWeight: 'bold', color: '#404041' };
  const h2Style = { fontSize: '14px', color: '#404041', fontWeight: 'bold' };
  const h3Style = { fontSize: '12px', color: '#404041', fontWeight: 'bold' };
  const pStyle = { fontSize: '10px', color: '#404041', fontWeight: 'bold' };

  const flexStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  };

  const MyDocument = () => {
    const [products, setProducts] = React.useState([]);
    useEffect(() => {
      if (data?.products?.length > 2) {
        const slicedProducts = [data?.products?.slice(0, 4)];
        for (let i = 4; i < data?.products?.length; i += 11) {
          slicedProducts.push(data?.products?.slice(i, i + 11));
        }
        setProducts(slicedProducts);
      } else {
        setProducts([data?.products]);
      }
    }, [data]);

    return (
      <Document>
        {products.length > 1 ? (
          products.map((item, index) => {
            if (index === products.length - 1) {
              return (
                <Page size="A4" style={styles.page}>
                  <InvoiceTable key={index} tableData={item} />
                  {/* body */}
                  <View
                    style={{
                      color: '#000',
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                      padding: '12 22',
                      marginBottom: 12
                    }}
                  >
                    <View
                      style={{
                        width: '56%',
                        fontSize: 12,
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {/* row */}
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Text
                          style={{
                            width: '78%',
                            textAlign: 'right',
                            ...pStyle
                          }}
                        >
                          الاجمالي{' '}
                        </Text>
                        <Text
                          style={{
                            width: '20%',
                            ...pStyle
                          }}
                        >
                          {data?.productPrice}
                        </Text>
                      </View>
                      {/* row */}
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Text
                          style={{
                            width: '78%',
                            textAlign: 'right',
                            ...pStyle
                          }}
                        >
                          ضريبة القيمة المضافة{' '}
                        </Text>
                        <Text
                          style={{
                            width: '20%',
                            ...pStyle
                          }}
                        >
                          {data?.taxPrice}
                        </Text>
                      </View>
                      {/* row */}
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Text
                          style={{
                            width: '78%',
                            textAlign: 'right',
                            ...pStyle
                          }}
                        >
                          اجمالي المبلغ شامل الضريبة
                        </Text>
                        <Text
                          style={{
                            width: '20%',
                            ...pStyle
                          }}
                        >
                          {data?.totalPrice}
                        </Text>
                      </View>
                      {/* row */}
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Text
                          style={{
                            width: '78%',
                            textAlign: 'right',
                            ...pStyle
                          }}
                        >
                          طريقة الدفع
                        </Text>
                        <Text
                          style={{
                            width: '20%',
                            ...pStyle
                          }}
                        >
                          {data?.paymentTypes
                            .map((item) => item?.name)
                            .join('/ ')}
                        </Text>
                      </View>
                      {/* row */}
                      {data?.paymentTypes.length > 1 &&
                        data?.paymentTypes?.map((item, index) => (
                          <View
                            key={index}
                            style={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Text
                              style={{
                                width: '78%',
                                textAlign: 'right',
                                ...pStyle
                              }}
                            >
                              {item?.name}
                            </Text>
                            <Text
                              style={{
                                width: '20%',
                                ...pStyle
                              }}
                            >
                              {item?.price}
                            </Text>
                          </View>
                        ))}
                      {/* row */}
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between',
                          borderBottom: '1px solid black',
                          paddingBottom: '16px',
                          marginBottom: '16px'
                        }}
                      >
                        <Text
                          style={{
                            width: '78%',
                            textAlign: 'right',
                            ...h1Style
                          }}
                        >
                          الاجمالي
                        </Text>
                        <Text
                          style={{
                            width: '20%',
                            ...h1Style
                          }}
                        >
                          {data?.totalPrice}
                        </Text>
                      </View>
                      {/* row */}
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Text
                          style={{
                            width: '78%',
                            textAlign: 'right',
                            ...pStyle
                          }}
                        >
                          المدفوع
                        </Text>
                        <Text
                          style={{
                            width: '20%',
                            ...pStyle
                          }}
                        >
                          {data?.deposit}
                        </Text>
                      </View>
                      {/* row */}
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Text
                          style={{
                            width: '78%',
                            textAlign: 'right',
                            ...pStyle
                          }}
                        >
                          الاجمالي المستحق
                        </Text>
                        <Text
                          style={{
                            width: '20%',
                            ...pStyle
                          }}
                        >
                          {data?.remainingAmount}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        width: '45%'
                      }}
                    ></View>
                  </View>
                  {/* footer */}
                  <InvoiceMainFooter
                    imageUrl={imageUrl}
                    zakatQr={zakatQr}
                    data={data}
                  />
                </Page>
              );
            } else if (index === 0) {
              return (
                <Page size="A4" style={styles.page}>
                  <View
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      padding: '22 0 5 0',
                      marginBottom: 5,
                      justifyContent: 'space-between',
                      borderBottomWidth: 1,
                      borderBottomStyle: 'solid',
                      borderBottomColor: '#bbb'
                    }}
                  >
                    <View
                      style={{
                        width: '32%',
                        height: 150,
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        // backgroundColor: '#404041',
                        borderTopLeftRadius: 999,
                        borderBottomLeftRadius: 999
                      }}
                    >
                      <Image
                        style={{
                          width: 100,
                          height: 100
                        }}
                        src={data?.setting?.accountInfo?.logo}
                        alt="sdf"
                      />
                    </View>

                    <View
                      style={{
                        width: '64%',
                        padding: '0 22',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end'
                      }}
                    >
                      <Text style={h1Style}>
                        {' '}
                        {data?.invoiceType == 1
                          ? 'فاتورة ضريبية مبسطة'
                          : 'فاتورة ضريبية'}{' '}
                      </Text>

                      <View
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end'
                        }}
                      >
                        <Text style={h2Style}>
                          {data?.setting?.branch?.name}
                        </Text>
                        <Text style={h2Style}>
                          {data?.setting?.branch?.address}
                        </Text>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            justifyContent: 'space-between',
                            width: 300
                          }}
                        >
                          <Text style={h3Style}>السجل التجاري</Text>
                          <Text
                            style={{
                              margin: '0 22',
                              fontSize: '16px'
                            }}
                          >
                            {data?.setting?.accountInfo?.commercialRecord}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            justifyContent: 'space-between',
                            width: 300
                          }}
                        >
                          {' '}
                          <Text style={h3Style}>الرقم الضريبي</Text>
                          <Text
                            style={{
                              margin: '0 22',
                              fontSize: '16px'
                            }}
                          >
                            {data?.setting?.accountInfo?.taxRecord}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        padding: '11 0',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        marginTop: '5px'
                      }}
                    >
                      <View
                        style={{
                          borderTop: '1px solid black',
                          width: '47%',
                          padding: '22 0'
                        }}
                      >
                        <View style={flexStyle}>
                          <Text style={h3Style}>رقم الفاتورة</Text>
                          <Text
                            style={pStyle}
                          >{`#${data?.invoiceNumber}`}</Text>
                        </View>
                        <View style={flexStyle}>
                          <Text style={h3Style}>تاريخ الاصدار</Text>
                          <Text style={pStyle}>{data?.date}</Text>
                        </View>
                        <View style={flexStyle}>
                          <Text style={h3Style}>الوقت</Text>
                          <Text style={pStyle}>{data?.time}</Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: '47%',
                          borderTop: '1px solid black',
                          padding: '22 0'
                        }}
                      >
                        {data?.client?.name && (
                          <View
                            style={{
                              ...flexStyle,
                              marginRight: 22
                            }}
                          >
                            <Text style={h3Style}>اسم العميل</Text>
                            <Text style={pStyle}>{data?.client?.name}</Text>
                          </View>
                        )}
                        {data?.client?.commercialRecord && (
                          <View
                            style={{
                              ...flexStyle,
                              marginRight: 22
                            }}
                          >
                            <Text style={h3Style}>السجل التجاري</Text>
                            <Text style={pStyle}>
                              {data?.client?.commercialRecord}
                            </Text>
                          </View>
                        )}
                        {data?.client?.taxRecord && (
                          <View
                            style={{
                              ...flexStyle,
                              marginRight: 22
                            }}
                          >
                            <Text style={h3Style}>الرقم الضريبي</Text>
                            <Text style={pStyle}>
                              {data?.client?.taxRecord}
                            </Text>
                          </View>
                        )}
                        {data?.client?.address && (
                          <View
                            style={{
                              ...flexStyle,
                              marginRight: 22
                            }}
                          >
                            <Text style={h3Style}> العنوان</Text>
                            <Text style={pStyle}>{data?.client?.address}</Text>
                          </View>
                        )}
                      </View>
                    </View>
                    <InvoiceTable key={index} tableData={item} />
                  </View>
                  {/* footer */}
                  <InvoiceMainFooter
                    imageUrl={imageUrl}
                    zakatQr={zakatQr}
                    data={data}
                  />
                </Page>
              );
            } else {
              return (
                <Page size="A4" style={styles.page}>
                  <InvoiceTable key={index} tableData={item} />
                  {/* footer */}
                  <InvoiceMainFooter
                    imageUrl={imageUrl}
                    zakatQr={zakatQr}
                    data={data}
                  />
                </Page>
              );
            }
          })
        ) : (
          <Page size="A4" style={styles.page}>
            <View
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                padding: '22 0 5 0',
                marginBottom: 5,
                justifyContent: 'space-between',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: '#bbb'
              }}
            >
              {/* {data?.setting?.accountInfo?.logo && ( */}
              <View
                style={{
                  width: '32%',
                  height: 150,
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  // backgroundColor: '#404041',
                  borderTopLeftRadius: 999,
                  borderBottomLeftRadius: 999
                }}
              >
                <Image
                  style={{
                    width: 100,
                    height: 100,
                    marginLeft: 4,
                    marginTop: 6
                  }}
                  src={data?.setting?.accountInfo?.logo}
                  alt="sdf"
                />
              </View>
              {/* )} */}

              <View
                style={{
                  width: '64%',
                  padding: '0 22',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end'
                }}
              >
                <Text style={h1Style}>
                  {data?.invoiceType == 1
                    ? 'فاتورة ضريبية مبسطة'
                    : 'فاتورة ضريبية'}{' '}
                </Text>

                <View
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end'
                  }}
                >
                  <Text style={h2Style}>{data?.setting?.branch?.name}</Text>
                  <Text style={h2Style}>{data?.setting?.branch?.address}</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                      width: 300
                    }}
                  >
                    <Text style={h3Style}>السجل التجاري</Text>
                    <Text
                      style={{
                        margin: '0 22',
                        fontSize: '16px'
                      }}
                    >
                      {data?.setting?.accountInfo?.commercialRecord}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                      width: 300
                    }}
                  >
                    {' '}
                    <Text style={h3Style}>الرقم الضريبي</Text>
                    <Text
                      style={{
                        margin: '0 22',
                        fontSize: '16px'
                      }}
                    >
                      {data?.setting?.accountInfo?.taxRecord}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  padding: '11 0',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  marginTop: '5px'
                }}
              >
                <View
                  style={{
                    borderTop: '1px solid black',
                    width: '47%',
                    padding: '22 0'
                  }}
                >
                  <View style={flexStyle}>
                    <Text style={h3Style}>رقم الفاتورة</Text>
                    <Text style={pStyle}>{`#${data?.invoiceNumber}`}</Text>
                  </View>
                  <View style={flexStyle}>
                    <Text style={h3Style}>تاريخ الاصدار</Text>
                    <Text style={pStyle}>{data?.date}</Text>
                  </View>
                  <View style={flexStyle}>
                    <Text style={h3Style}>الوقت</Text>
                    <Text style={pStyle}>{data?.time}</Text>
                  </View>
                </View>
                <View
                  style={{
                    width: '47%',
                    borderTop: '1px solid black',
                    padding: '22 0'
                  }}
                >
                  {data?.client?.name && (
                    <View
                      style={{
                        ...flexStyle,
                        marginRight: 22
                      }}
                    >
                      <Text style={h3Style}>اسم العميل</Text>
                      <Text style={pStyle}>{data?.client?.name}</Text>
                    </View>
                  )}
                  {data?.client?.commercialRecord && (
                    <View
                      style={{
                        ...flexStyle,
                        marginRight: 22
                      }}
                    >
                      <Text style={h3Style}>السجل التجاري</Text>
                      <Text style={pStyle}>
                        {data?.client?.commercialRecord}
                      </Text>
                    </View>
                  )}
                  {data?.client?.taxRecord && (
                    <View
                      style={{
                        ...flexStyle,
                        marginRight: 22
                      }}
                    >
                      <Text style={h3Style}>الرقم الضريبي</Text>
                      <Text style={pStyle}>{data?.client?.taxRecord}</Text>
                    </View>
                  )}
                  {data?.client?.address && (
                    <View
                      style={{
                        ...flexStyle,
                        marginRight: 22
                      }}
                    >
                      <Text style={h3Style}> العنوان</Text>
                      <Text style={pStyle}>{data?.client?.address}</Text>
                    </View>
                  )}
                </View>
              </View>
              <InvoiceTable tableData={data?.products} />
            </View>
            <View
              style={{
                color: '#000',
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                padding: '12 22',
                marginBottom: 12
              }}
            >
              <View
                style={{
                  width: '56%',
                  fontSize: 12,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {/* row */}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between'
                  }}
                >
                  <Text
                    style={{
                      width: '78%',
                      textAlign: 'right',
                      ...pStyle
                    }}
                  >
                    الاجمالي{' '}
                  </Text>
                  <Text
                    style={{
                      width: '20%',
                      ...pStyle
                    }}
                  >
                    {data?.productPrice}
                  </Text>
                </View>
                {/* row */}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between'
                  }}
                >
                  <Text
                    style={{
                      width: '78%',
                      textAlign: 'right',
                      ...pStyle
                    }}
                  >
                    ضريبة القيمة المضافة{' '}
                  </Text>
                  <Text
                    style={{
                      width: '20%',
                      ...pStyle
                    }}
                  >
                    {data?.taxPrice}
                  </Text>
                </View>
                {/* row */}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between'
                  }}
                >
                  <Text
                    style={{
                      width: '78%',
                      textAlign: 'right',
                      ...pStyle
                    }}
                  >
                    اجمالي المبلغ شامل الضريبة
                  </Text>
                  <Text
                    style={{
                      width: '20%',
                      ...pStyle
                    }}
                  >
                    {data?.totalPrice}
                  </Text>
                </View>
                {/* row */}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between'
                  }}
                >
                  <Text
                    style={{
                      width: '78%',
                      textAlign: 'right',
                      ...pStyle
                    }}
                  >
                    طريقة الدفع
                  </Text>
                  <Text
                    style={{
                      width: '20%',
                      ...pStyle
                    }}
                  >
                    {data?.paymentTypes.map((item) => item?.name).join('/ ')}
                  </Text>
                </View>
                {/* row */}
                {data?.paymentTypes.length > 1 &&
                  data?.paymentTypes?.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Text
                        style={{
                          width: '78%',
                          textAlign: 'right',
                          ...pStyle
                        }}
                      >
                        {item?.name}
                      </Text>
                      <Text
                        style={{
                          width: '20%',
                          ...pStyle
                        }}
                      >
                        {item?.price}
                      </Text>
                    </View>
                  ))}
                {/* row */}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid black',
                    paddingBottom: '16px',
                    marginBottom: '16px'
                  }}
                >
                  <Text
                    style={{
                      width: '78%',
                      textAlign: 'right',
                      ...h1Style
                    }}
                  >
                    الاجمالي
                  </Text>
                  <Text
                    style={{
                      width: '20%',
                      ...h1Style
                    }}
                  >
                    {data?.totalPrice}
                  </Text>
                </View>
                {/* row */}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between'
                  }}
                >
                  <Text
                    style={{
                      width: '78%',
                      textAlign: 'right',
                      ...pStyle
                    }}
                  >
                    المدفوع
                  </Text>
                  <Text
                    style={{
                      width: '20%',
                      ...pStyle
                    }}
                  >
                    {data?.deposit}
                  </Text>
                </View>
                {/* row */}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between'
                  }}
                >
                  <Text
                    style={{
                      width: '78%',
                      textAlign: 'right',
                      ...pStyle
                    }}
                  >
                    الاجمالي المستحق
                  </Text>
                  <Text
                    style={{
                      width: '20%',
                      ...pStyle
                    }}
                  >
                    {data?.remainingAmount}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '45%'
                }}
              ></View>
            </View>
            {/* footer */}
            <InvoiceMainFooter
              imageUrl={imageUrl}
              zakatQr={zakatQr}
              data={data}
            />
          </Page>
        )}
      </Document>
    );
  };

  const isMobile = () => {
    if (
      /Android/i.test(navigator.userAgent) ||
      /iPhone|iPad|iPod/i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {!isMobile() && (
        <PDFViewer
          style={{
            // 'transform:scale': '80%',

            width: '100%',
            maxWidth: '992px',
            height: '100%',
            outline: 0,
            borderRadius: 18,
            border: '1px solid rgba(255, 255, 255, 0.2)'
          }}
        >
          <MyDocument />
        </PDFViewer>
      )}
      {isMobile() && (
        <PDFDownloadLink document={<MyDocument />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'حمل الفاتورة'
          }
        </PDFDownloadLink>
      )}
    </>
  );
};

export default InvoicePdf;
