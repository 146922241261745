const apisUrls = {
  getAdmins: (filterObj) =>
    `/admin/get?searchText=${filterObj?.searchText || ''}&status=${
      filterObj?.status || ''
    }&page=${filterObj?.page || 1}`,
  getSingleAdmin: '/admin/single',
  createAdmin: '/admin/create',
  updateAdmin: '/admin/update',
  deleteAdmin: '/admin/delete',
  toggleAdmin: '/admin/changeStatus',
  // app packages
  getAppPackages: (filterObj) =>
    `/subscription/package/get?searchText=${filterObj?.searchText || ''}&sort=${
      filterObj?.sort || ''
    }&page=${filterObj?.page || 1}`,
  getSingleAppPackage: '/Package/single',
  createAppPackage: '/subscription/package/create',
  updateAppPackage: '/subscription/package/update',
  deleteAppPackage: '/subscription/package/delete',
  toggleAppPackage: '/subscription/package/change_status',
  //
  // Devices urls
  getDevices: `/subscription/device/get`,
  getSingleDevice: '/subscription/device/single',
  createDevice: '/subscription/device/create',
  updateDevice: '/subscription/device/update',
  deleteDevice: '/subscription/device/delete',
  //
  // Devices brands urls
  getDevicesBrands: `/subscription/deviceBrand/get`,
  getSingleDeviceBrand: '/subscription/deviceBrand/single',
  createDeviceBrand: '/subscription/deviceBrand/create',
  updateDeviceBrand: '/subscription/deviceBrand/update',
  deleteDeviceBrand: '/subscription/deviceBrand/delete',
  toggleDeviceBrand: '/subscription/deviceBrand/change_status',
  //
  // Countries urls
  getCountries: `/subscription/country/get`,
  getSingleCountry: '/subscription/country/single',
  createCountry: '/subscription/country/create',
  updateCountry: '/subscription/country/update',
  deleteCountry: '/subscription/country/delete',
  // Admins urls
  getWebsiteRequests: (filterObj) =>
    `/companyRequest/get?searchText=${
      filterObj?.searchText || ''
    }&sort=${filterObj?.sort || ''}&page=${filterObj?.page || 1}`,
  // Clients urls
  getClients: (filterObj) =>
    `/user/get?searchText=${filterObj?.searchText || ''}&status=${
      filterObj?.status || ''
    }&sort=${filterObj?.sort || ''}&archive=${filterObj?.archive || ''}&page=${
      filterObj?.page || 1
    }`,
  getSingleClient: '/user/single',
  createClient: '/user/create',
  changeClientStatus: '/user/changeStatus',
  updateClient: '/user/update',
  deleteClient: '/user/delete',
  //
  // Influencers urls
  getInfluencers: (filterObj) =>
    `/subscription/influencer/get?searchText=${
      filterObj?.searchText || ''
    }&sort=${filterObj?.sort || ''}&page=${filterObj?.page || 1}`,
  getSingleInfluencer: '/subscription/influencer/single',
  getInfluencerSocials: '/subscription/influencer/socials',
  createInfluencer: '/subscription/influencer/create',
  updateInfluencer: '/subscription/influencer/update',
  deleteInfluencer: '/subscription/influencer/delete',
  //
  // payment types
  getPaymentTypes: `/PaymentType/get`,
  updatePaymentType: '/PaymentType/update',
  createPaymentType: '/PaymentType/create',
  //
  // app guide
  getAppGuides: (filterObj) => '/Help/get',
  getSingleAppGuideUrl: (filterObj) => `/Help/single?id=${filterObj?.id || ''}`,
  createAppGuiderUrl: '/Help/create',
  updateAppGuiderUrl: '/Help/update',
  deleteAppGuiderUrl: '/Help/delete',

  // website
  // // website servcies
  getWebsiteServicesUrl: (filterObj) => '/OurService/getService',
  getSingleWebsiteServiceUrl: (filterObj) =>
    `/OurService/single?id=${filterObj?.id || ''}`,
  createWebsiteServiceUrl: '/OurService/create',
  changeWebsiteServiceStatus: '/OurService/changeStatus',
  updateWebsiteServiceUrl: '/OurService/update',
  deleteWebsiteServiceUrl: '/OurService/delete',
  //
  // // website servcies features
  getWebsiteServicesFeaturesUrl: (filterObj) => '/OurService/getService2',
  //
  // // website partners
  getWebsitePartnersUrl: (filterObj) => '/partner/get',
  getSingleWebsitePartnerUrl: (filterObj) =>
    `/partner/single?id=${filterObj?.id || ''}`,
  createWebsitePartnerUrl: '/partner/create',
  updateWebsitePartnerUrl: '/partner/update',
  deleteWebsitePartnerUrl: '/partner/delete',
  //
  // // website packages
  getWebsitePackagesUrl: (filterObj) => '/OurPackage/get',
  getSingleWebsitePackageUrl: (filterObj) =>
    `/OurPackage/single?id=${filterObj?.id || ''}`,
  createWebsitePackageUrl: '/OurPackage/create',
  changeWebsitePackageStatus: '/OurPackage/changeStatus',
  updateWebsitePackageUrl: '/OurPackage/update',
  deleteWebsitePackageUrl: '/OurPackage/delete',
  //
  // // website experience
  getWebsiteExperiencesUrl: (filterObj) => '/Experience/get',
  getSingleWebsiteExperienceUrl: (filterObj) =>
    `/Experience/single?id=${filterObj?.id || ''}`,
  createWebsiteExperienceUrl: '/Experience/create',
  updateWebsiteExperienceUrl: '/Experience/update',
  deleteWebsiteExperienceUrl: '/Experience/delete',
  deleteWebsiteExperienceImageUrl: '/Experience/deleteImage',
  //
  // // website contacts
  getWebsiteContactsUrl: (filterObj) => '/Contact/get',
  getSingleWebsiteContactUrl: (filterObj) =>
    `/Contact/single?id=${filterObj?.id || ''}`,
  deleteSingleWebsiteContactUrl: '/Contact/delete',
  // section titles
  getSectiontitles: (filterObj) => `/SectionTitle/get`,
  getSingleSectionTitle: (filterObj) =>
    `/SectionTitle/single?id=${filterObj?.id || ''}`,
  updateSingleSectionTitle: '/SectionTitle/update'
};

export default apisUrls;
