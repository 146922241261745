/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './TitleDescForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import { updateSectionTitleAction } from '../../redux/section-titles-slice/SectionTitlesSliceThunkApi';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const schema = Yup.object().shape({
  title_ar: Yup.string().required('من فضلك ادخل العنوان الرئيسى بالعربية'),
  title_en: Yup.string().required('من فضلك ادخل العنوان الرئيسى بالانجليزية'),
  subTitle_ar: Yup.string().required('من فضلك ادخل العنوان الفرعى بالعربية'),
  subTitle_en: Yup.string().required('من فضلك ادخل العنوان الفرعى بالانجليزية')
});

const { medalIcon } = imagesSrcs;

const emptyFormValues = {
  title_ar: '',
  title_en: '',
  subTitle_ar: '',
  subTitle_en: ''
};

const TitleDescForm = (props) => {
  const dispatch = useDispatch();
  const fetchedSingleTitle = useSelector(
    (store) => store.sectionTitles.fetchedSingleTitle
  );
  const isSubmitting = useSelector((store) => store.sectionTitles.isSubmitting);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: emptyFormValues
  });

  // handle initial values
  useEffect(() => {
    if (fetchedSingleTitle) {
      setValue('title_ar', fetchedSingleTitle?.title_ar || '');
      setValue('title_en', fetchedSingleTitle?.title_en || '');
      setValue('subTitle_ar', fetchedSingleTitle?.subTitle_ar || '');
      setValue('subTitle_en', fetchedSingleTitle?.subTitle_en || '');
    }
  }, [fetchedSingleTitle]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('title_ar', data.title_ar || '');
    formData.append('title_en', data.title_en || '');
    formData.append('subTitle_ar', data.subTitle_ar || '');
    formData.append('subTitle_en', data.subTitle_en || '');
    formData.append('id', fetchedSingleTitle?.id || '');

    dispatch(
      updateSectionTitleAction({
        dataToBeSubmitted: formData
      })
    );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className={`custom-shared-form title-desc-form ${
        props?.className ? props.className : ''
      }`}
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الاساسى بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="title_ar"
            type="text"
            placeholder={'العنوان الاساسى بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.title_ar?.message}
            validateStatus={errors?.title_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الاساسى بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="title_en"
            type="text"
            placeholder={'العنوان الاساسى بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.title_en?.message}
            validateStatus={errors?.title_en ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الفرعى بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="subTitle_ar"
            type="text"
            placeholder={'العنوان الفرعى بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.subTitle_ar?.message}
            validateStatus={errors?.subTitle_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الفرعى بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="subTitle_en"
            type="text"
            placeholder={'العنوان الفرعى بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.subTitle_en?.message}
            validateStatus={errors?.subTitle_en ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              reset({
                title_ar: fetchedSingleTitle?.title_ar || '',
                title_en: fetchedSingleTitle?.title_en || '',
                subTitle_ar: fetchedSingleTitle?.subTitle_ar || '',
                subTitle_en: fetchedSingleTitle?.subTitle_en || ''
              });
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {isSubmitting && <LoadingModal />}
    </Form>
  );
};

export default TitleDescForm;
