/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import './WebsiteSettingsPage.scss';
import WebsiteSettingsForm from './WebsiteSettingsForm';

const WebsiteSettingsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="website-settings-page">
      <div className="page-title">
        <h3>الموقع الالكترونى</h3>
        <h4>لوحة العرض</h4>
      </div>

      <WebsiteSettingsForm />
    </div>
  );
};

export default WebsiteSettingsPage;
