import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPostFormModalOpened,
  setSelectedTableRow
} from '../../redux/payment-types-slice/paymentTypesSlice';
import PaymentTypesForm from './PaymentTypesForm';

const PaymentTypeFormModal = () => {
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.paymentTypes.selectedTableRow
  );
  const postFormModalOpened = useSelector(
    (store) => store.paymentTypes.postFormModalOpened
  );

  return (
    <Modal
      destroyOnClose
      className={`shared-custom-modal`}
      width="96%"
      style={{ maxWidth: '542px' }}
      title={selectedTableRow ? 'تعديل طريقة الدفع' : 'إضافة طريقة دفع جديدة'}
      open={postFormModalOpened}
      // onOk={() => {
      //   setModalOpened(false);
      // }}
      onCancel={() => {
        dispatch(setPostFormModalOpened(false));
        dispatch(setSelectedTableRow(null));
      }}
      footer={false}
    >
      <PaymentTypesForm />
    </Modal>
  );
};

export default PaymentTypeFormModal;
