import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changeWebsitePackageStatusApi,
  createWebsitePackageApi,
  deleteWebsitePackageApi,
  getAllWebsitePackagesApi,
  getSingleWebsitePackageApi
} from '../../apis/website-packages-apis/websitePackagesApis';
import customApiRequest from '../../helpers/customApiRequest';

export const getWebsitePackagessAction = createAsyncThunk(
  'websitePackages/getWebsitePackagessAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllWebsitePackagesApi(filterObj),
      thunkAPI
    });
  }
);

export const getSingleWebsitePackageAction = createAsyncThunk(
  'websitePackages/getSingleWebsitePackageAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSingleWebsitePackageApi(filterObj),
      thunkAPI
    });
  }
);

// create website package
export const createWebsitePackageAction = createAsyncThunk(
  'websitePackages/createWebsitePackageAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWebsitePackageApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//
// update website package
export const updateWebsitePackageAction = createAsyncThunk(
  'websitePackages/updateWebsitePackageAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWebsitePackageApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);
//
// change website package status
export const changeWebsitePackageStatusAction = createAsyncThunk(
  'websitePackages/changeWebsitePackageStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeWebsitePackageStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//

export const deleteWebsitePackageAction = createAsyncThunk(
  'websitePackages/deleteWebsitePackageAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteWebsitePackageApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
