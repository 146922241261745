import React from 'react';

const WalletIconAppbar = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.4867 14.3466C11.36 14.3466 11.2333 14.2999 11.1333 14.1999C10.94 14.0066 10.94 13.6866 11.1333 13.4933L13.4933 11.1333C13.6867 10.9399 14.0067 10.9399 14.2 11.1333C14.3933 11.3266 14.3933 11.6466 14.2 11.8399L11.84 14.1999C11.7467 14.2933 11.6133 14.3466 11.4867 14.3466Z"
        fill={color || '#fff'}
      />
      <path
        d="M13.8467 14.3466C13.72 14.3466 13.5933 14.2999 13.4933 14.1999L11.1333 11.8399C10.94 11.6466 10.94 11.3266 11.1333 11.1333C11.3267 10.9399 11.6467 10.9399 11.84 11.1333L14.2 13.4933C14.3933 13.6866 14.3933 14.0066 14.2 14.1999C14.1 14.2933 13.9733 14.3466 13.8467 14.3466Z"
        fill={color || '#fff'}
      />
      <path
        d="M4.66669 9.5C4.39335 9.5 4.16669 9.27333 4.16669 9V7C4.16669 6.72667 4.39335 6.5 4.66669 6.5C4.94002 6.5 5.16669 6.72667 5.16669 7V9C5.16669 9.27333 4.94002 9.5 4.66669 9.5Z"
        fill={color || '#fff'}
      />
      <path
        d="M8 9.5C7.72667 9.5 7.5 9.27333 7.5 9V7C7.5 6.72667 7.72667 6.5 8 6.5C8.27333 6.5 8.5 6.72667 8.5 7V9C8.5 9.27333 8.27333 9.5 8 9.5Z"
        fill={color || '#fff'}
      />
      <path
        d="M11.3333 9.5C11.06 9.5 10.8333 9.27333 10.8333 9V7C10.8333 6.72667 11.06 6.5 11.3333 6.5C11.6066 6.5 11.8333 6.72667 11.8333 7V9C11.8333 9.27333 11.6066 9.5 11.3333 9.5Z"
        fill={color || '#fff'}
      />
      <path
        d="M8.66665 15.1666H5.99998C2.37998 15.1666 0.833313 13.6199 0.833313 9.99992V5.99992C0.833313 2.37992 2.37998 0.833252 5.99998 0.833252H9.99998C13.62 0.833252 15.1666 2.37992 15.1666 5.99992V8.66659C15.1666 8.93992 14.94 9.16659 14.6666 9.16659C14.3933 9.16659 14.1666 8.93992 14.1666 8.66659V5.99992C14.1666 2.92659 13.0733 1.83325 9.99998 1.83325H5.99998C2.92665 1.83325 1.83331 2.92659 1.83331 5.99992V9.99992C1.83331 13.0733 2.92665 14.1666 5.99998 14.1666H8.66665C8.93998 14.1666 9.16665 14.3933 9.16665 14.6666C9.16665 14.9399 8.93998 15.1666 8.66665 15.1666Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default WalletIconAppbar;
