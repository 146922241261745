import React from 'react';

const NotesIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.08 26.9067C20.7333 26.9067 20.3733 26.8934 19.9867 26.8534C19.2933 26.8001 18.5067 26.6667 17.6933 26.4667L15.4533 25.9334C9.30667 24.4801 7.29334 21.2267 8.73334 15.0934L10.04 9.50673C10.3333 8.24006 10.68 7.21339 11.1067 6.36006C13.4 1.62673 17.7867 2.05339 20.9067 2.78673L23.1333 3.30673C26.2533 4.04006 28.2267 5.20006 29.3333 6.97339C30.4267 8.74673 30.6 11.0267 29.8667 14.1467L28.56 19.7201C27.4133 24.6001 25.0267 26.9067 21.08 26.9067ZM17.4933 4.33339C15.2667 4.33339 13.8533 5.25339 12.9067 7.22673C12.56 7.94673 12.2533 8.84006 11.9867 9.96006L10.68 15.5467C9.49334 20.5867 10.8667 22.7867 15.9067 23.9867L18.1467 24.5201C18.8667 24.6934 19.5467 24.8001 20.16 24.8534C23.7733 25.2134 25.5867 23.6267 26.6 19.2667L27.9067 13.6934C28.5067 11.1201 28.4267 9.32006 27.6267 8.02673C26.8267 6.73339 25.2533 5.85339 22.6667 5.25339L20.44 4.73339C19.3333 4.46673 18.3467 4.33339 17.4933 4.33339Z"
        fill={color || '#292D32'}
      />
      <path
        d="M11.1067 29.6666C7.68 29.6666 5.49333 27.6133 4.09333 23.2799L2.38666 18.0133C0.493331 12.1466 2.18666 8.83995 8.02666 6.94661L10.1333 6.26661C10.8267 6.05328 11.3467 5.90661 11.8133 5.82661C12.1867 5.74661 12.5733 5.89328 12.8 6.19995C13.0267 6.50661 13.0667 6.90661 12.9067 7.25328C12.56 7.95995 12.2533 8.85328 12 9.97328L10.6933 15.5599C9.50666 20.5999 10.88 22.7999 15.92 23.9999L18.16 24.5333C18.88 24.7066 19.56 24.8133 20.1733 24.8666C20.6 24.9066 20.9467 25.1999 21.0667 25.6133C21.1733 26.0266 21.0133 26.4533 20.6667 26.6933C19.7867 27.2933 18.68 27.7999 17.28 28.2533L15.1733 28.9466C13.64 29.4266 12.3067 29.6666 11.1067 29.6666ZM10.3733 8.29328L8.65333 8.85328C3.89333 10.3866 2.76 12.6266 4.29333 17.3999L6 22.6666C7.54666 27.4266 9.78666 28.5733 14.5467 27.0399L16.6533 26.3466C16.7333 26.3199 16.8 26.2933 16.88 26.2666L15.4667 25.9333C9.32 24.4799 7.30666 21.2266 8.74666 15.0933L10.0533 9.50661C10.1467 9.07995 10.2533 8.66661 10.3733 8.29328Z"
        fill={color || '#292D32'}
      />
    </svg>
  );
};

export default NotesIcon;
