import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createWebsiteExperienceApi,
  deleteWebsiteExperienceApi,
  deleteWebsiteExperienceImageApi,
  getAllWebsiteExperiencesApi,
  getSingleWebsiteExperienceApi
} from '../../apis/website-experience-apis/websiteExperienceApis';
import customApiRequest from '../../helpers/customApiRequest';

export const getWebsiteExperiencesAction = createAsyncThunk(
  'websiteExperiences/getWebsiteExperiencesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllWebsiteExperiencesApi(filterObj),
      thunkAPI
    });
  }
);

export const getSingleWebsiteExperienceAction = createAsyncThunk(
  'websiteExperiences/getSingleWebsiteExperienceAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSingleWebsiteExperienceApi(filterObj),
      thunkAPI
    });
  }
);

// create website exp
export const createWebsiteExperienceAction = createAsyncThunk(
  'websiteExperiences/createWebsiteExperienceAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWebsiteExperienceApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//
// update website exp
export const updateWebsiteExperienceAction = createAsyncThunk(
  'websiteExperiences/updateWebsiteExperienceAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWebsiteExperienceApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);
//
//

export const deleteWebsiteExperienceAction = createAsyncThunk(
  'websiteExperiences/deleteWebsiteExperienceAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteWebsiteExperienceApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteWebsiteExperienceImageAction = createAsyncThunk(
  'websiteExperiences/deleteWebsiteExperienceImageAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteWebsiteExperienceImageApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
