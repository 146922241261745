import React from 'react';

const CheckmarkIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.19333 10.1134C7.06 10.1134 6.93333 10.06 6.84 9.96669L5.22667 8.35335C5.03333 8.16002 5.03333 7.84002 5.22667 7.64669C5.42 7.45335 5.74 7.45335 5.93333 7.64669L7.19333 8.90669L10.06 6.04002C10.2533 5.84669 10.5733 5.84669 10.7667 6.04002C10.96 6.23335 10.96 6.55335 10.7667 6.74669L7.54666 9.96669C7.45333 10.06 7.32666 10.1134 7.19333 10.1134Z"
        fill={color || '#fff'}
      />
      <path
        d="M8 15.1667C7.58 15.1667 7.16 15.0267 6.83334 14.7467L5.78 13.84C5.67334 13.7467 5.40667 13.6534 5.26667 13.6534H4.12C3.13334 13.6534 2.33334 12.8534 2.33334 11.8667V10.7267C2.33334 10.5867 2.24 10.3267 2.14667 10.22L1.24667 9.16004C0.700003 8.51337 0.700003 7.49337 1.24667 6.84671L2.14667 5.78671C2.24 5.68004 2.33334 5.42004 2.33334 5.28004V4.13337C2.33334 3.14671 3.13334 2.34671 4.12 2.34671H5.27334C5.41334 2.34671 5.68 2.24671 5.78667 2.16004L6.84 1.25337C7.49334 0.693374 8.51334 0.693374 9.16667 1.25337L10.22 2.16004C10.3267 2.25337 10.5933 2.34671 10.7333 2.34671H11.8667C12.8533 2.34671 13.6533 3.14671 13.6533 4.13337V5.26671C13.6533 5.40671 13.7533 5.67337 13.8467 5.78004L14.7533 6.83337C15.3133 7.48671 15.3133 8.50671 14.7533 9.16004L13.8467 10.2134C13.7533 10.32 13.6533 10.5867 13.6533 10.7267V11.86C13.6533 12.8467 12.8533 13.6467 11.8667 13.6467H10.7333C10.5933 13.6467 10.3267 13.7467 10.22 13.8334L9.16667 14.74C8.84 15.0267 8.42 15.1667 8 15.1667ZM4.12 3.34671C3.68667 3.34671 3.33334 3.70004 3.33334 4.13337V5.27337C3.33334 5.65337 3.15334 6.14004 2.90667 6.42671L2.00667 7.48671C1.77334 7.76004 1.77334 8.23337 2.00667 8.50671L2.90667 9.56671C3.15334 9.86004 3.33334 10.34 3.33334 10.72V11.86C3.33334 12.2934 3.68667 12.6467 4.12 12.6467H5.27334C5.66 12.6467 6.14667 12.8267 6.44 13.08L7.49334 13.9867C7.76667 14.22 8.24667 14.22 8.52 13.9867L9.57334 13.08C9.86667 12.8334 10.3533 12.6467 10.74 12.6467H11.8733C12.3067 12.6467 12.66 12.2934 12.66 11.86V10.7267C12.66 10.34 12.84 9.85337 13.0933 9.56004L14 8.50671C14.2333 8.23337 14.2333 7.75337 14 7.48004L13.0933 6.42671C12.84 6.13337 12.66 5.64671 12.66 5.26004V4.13337C12.66 3.70004 12.3067 3.34671 11.8733 3.34671H10.74C10.3533 3.34671 9.86667 3.16671 9.57334 2.91337L8.52 2.00671C8.24667 1.77337 7.76667 1.77337 7.49334 2.00671L6.44 2.92004C6.14667 3.16671 5.65334 3.34671 5.27334 3.34671H4.12Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default CheckmarkIcon;
