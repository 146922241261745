/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import TitleDescForm from '../../components/title-desc-form/TitleDescForm';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import { setFetchedSingleTitle } from '../../redux/section-titles-slice/SectionTitlesSlice';
import { getSingleSectionTitlesAction } from '../../redux/section-titles-slice/SectionTitlesSliceThunkApi';
import {
  setConfirmModal,
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/website-packages-slice/websitePackagesSlice';
import {
  changeWebsitePackageStatusAction,
  deleteWebsitePackageAction,
  getWebsitePackagessAction
} from '../../redux/website-packages-slice/websitePackagesSliceThunkApi';
import WebsitePackageForm from './WebsitePackageForm';
import './WebsitePackagesPage.scss';
import WebsitePackagesPageFilter from './WebsitePackagesPageFilter';
import WebsitePackagesPageTable from './WebsitePackagesPageTable';

const WebsitePackagesPage = () => {
  const dispatch = useDispatch();
  const fetchSingleTitleCount = useSelector(
    (store) => store.sectionTitles.fetchSingleCount
  );
  const isSubmitting = useSelector(
    (store) => store.websitePackages.isSubmitting
  );
  const websitePackageFormOpened = useSelector(
    (store) => store.websitePackages.websitePackageFormOpened
  );
  const fetchDataCount = useSelector(
    (store) => store.websitePackages.fetchDataCount
  );
  const selectedTableRow = useSelector(
    (store) => store.websitePackages.selectedTableRow
  );
  const opened = useSelector(
    (store) => store.websitePackages.confirmModal.opened
  );
  const deleteModalOpened = useSelector(
    (store) => store.websitePackages.deleteModalOpened
  );
  const type = useSelector((store) => store.websitePackages.confirmModal.type);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let isMounted = true;

  const handleTogglePackage = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('id', selectedTableRow.id);
      dispatch(
        changeWebsitePackageStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDeletePackage = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('id', selectedTableRow.id);
      dispatch(
        deleteWebsitePackageAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  useEffect(() => {
    if (isMounted) {
      dispatch(
        getSingleSectionTitlesAction({
          filterObj: {
            id: 2
          }
        })
      );
    }

    return () => dispatch(setFetchedSingleTitle(null));
  }, [fetchSingleTitleCount]);

  useEffect(() => {
    if (isMounted)
      dispatch(
        getWebsitePackagessAction({
          filterObj: null
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount]);

  return (
    <div className="website-page website-packages-page">
      <p className="website-title">الموقع الالكترونى</p>
      <p className="page-title">الباقات</p>

      <TitleDescForm className="in-packages-page" />

      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}

      <div>
        <AnimatePresence>
          {!websitePackageFormOpened && (
            <motion.div
              // layout
              initial={{
                opacity: 0,
                translateX: 1700,
                height: 0,
                transition: {
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.5 }
                }
              }}
              animate={{
                height: 'auto',
                opacity: 1,
                translateX: 0,
                transition: {
                  delay: 0.7,
                  opacity: { ease: 'linear', duration: 0.8 },
                  translateX: { ease: 'linear', duration: 0.5 }
                }
              }}
              exit={{
                opacity: 0,
                translateX: 800,
                transition: {
                  opacity: { ease: 'linear', duration: 0.5 },
                  translateX: { ease: 'linear', duration: 0.5 }
                }
              }}
              className="page-main-content"
            >
              <div className="page-title">الباقات والاشتراكات</div>
              <WebsitePackagesPageFilter />
              <WebsitePackagesPageTable />
            </motion.div>
          )}
        </AnimatePresence>

        {/* PACKAGE FORM */}
        <AnimatePresence>
          {websitePackageFormOpened && (
            <motion.div
              initial={{
                opacity: 0,
                translateX: 700,
                transition: {
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.5 }
                }
              }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: {
                  delay: 0.7,
                  opacity: { ease: 'linear', duration: 0.5 },
                  translateX: { ease: 'linear', duration: 0.5 }
                }
              }}
              exit={{
                opacity: 0,
                translateX: 800,
                transition: {
                  // delay: 0.7,
                  opacity: { ease: 'linear', duration: 0.5 },
                  translateX: { ease: 'linear', duration: 0.5 }
                }
              }}
              // transition={{
              //   delay: 0.7,
              //   opacity: { ease: 'linear', duration: 0.5 },
              //   width: { ease: 'linear', duration: 0.5 },
              //   translateX: { ease: 'linear', duration: 0.5 }
              // }}
              className="device-form-wrapper"
            >
              <WebsitePackageForm />
            </motion.div>
          )}
        </AnimatePresence>
        {/* End package form */}
      </div>

      {/* delete package modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedTableRow(null));
          }
          dispatch(setDeleteModalOpened(v));
        }}
        handleAccept={handleDeletePackage}
      >
        <h3>{`هل انت متأكد من حذف الباقة ${selectedTableRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* end delete package modal */}

      {/* confirm package modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={opened}
        modalType={type}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedTableRow(null));
          }
          dispatch(
            setConfirmModal({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleTogglePackage}
      >
        {type === confirmModalTypesValues?.successModal && (
          <h3>{`هل انت متأكد من تفعيل الباقة ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
        {type === confirmModalTypesValues?.errorModal && (
          <h3>{`هل انت متأكد من ايقاف عمل الباقة ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
      </ConfirmModal>
      {/* end confirm package modal */}

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default WebsitePackagesPage;
