/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React from 'react';

import 'quill/dist/quill.snow.css';
import { useEffect } from 'react';

const CustomQuill = ({ quill, quillRef, label, initialValue, onChange }) => {
  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        // console.log(label, quill.root.innerHTML);
        onChange &&
          typeof onChange === 'function' &&
          onChange(quill.root.innerHTML);
      });
      if (initialValue)
        quill.clipboard.dangerouslyPasteHTML(initialValue || '');
    }
  }, [quill, initialValue]);
  return (
    <div className="quill-wrapper">
      <h4 className="card-title">{label}</h4>
      <div className="snow-editor">
        <div ref={quillRef} />
      </div>
    </div>
  );
};

export default CustomQuill;
