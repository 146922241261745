import React from 'react';

const CrownIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.3333 14.0266H4.66665C2.55331 14.0266 0.833313 12.3066 0.833313 10.1933V3.80661C0.833313 2.93994 1.23331 2.63994 1.47331 2.53994C1.71331 2.43994 2.20665 2.36661 2.81998 2.97994L4.54665 4.70661C4.61331 4.77328 4.71998 4.77328 4.77998 4.70661L7.17331 2.31328C7.61331 1.87328 8.38665 1.87328 8.81998 2.31328L11.2133 4.70661C11.28 4.77328 11.3866 4.77328 11.4466 4.70661L13.1733 2.97994C13.7866 2.36661 14.28 2.44661 14.52 2.53994C14.76 2.63994 15.16 2.93328 15.16 3.80661V10.1999C15.1666 12.4866 13.6266 14.0266 11.3333 14.0266ZM1.87331 3.49328C1.85331 3.54661 1.83331 3.64661 1.83331 3.80661V10.1999C1.83331 11.7599 3.10665 13.0333 4.66665 13.0333H11.3333C13.0533 13.0333 14.1666 11.9199 14.1666 10.1999V3.80661C14.1666 3.64661 14.1466 3.55328 14.1266 3.49994C14.0733 3.52661 13.9933 3.57994 13.88 3.69328L12.1533 5.41994C11.7133 5.85994 10.94 5.85994 10.5066 5.41994L8.11331 3.02661C8.04665 2.95994 7.93998 2.95994 7.87998 3.02661L5.49331 5.41328C5.05331 5.85328 4.27998 5.85328 3.84665 5.41328L2.11998 3.68661C2.00665 3.57328 1.91998 3.51994 1.87331 3.49328Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default CrownIcon;
