import { createAsyncThunk } from '@reduxjs/toolkit';
import createDeviceApi from '../../apis/devices-apis/createDeviceApi';
import deleteDeviceApi from '../../apis/devices-apis/deleteDeviceApi';
import getAllDevicesApi from '../../apis/devices-apis/getAllDevicesApi';
import customApiRequest from '../../helpers/customApiRequest';
import axios from 'axios';
import apisUrls from '../../apis/urls';

export const getDevicesAction = createAsyncThunk(
  'devices/getDevicesAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllDevicesApi(null),
      thunkAPI
    });
  }
);
export const getCountries = createAsyncThunk(
  'General/countries',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await axios.get(apisUrls.getCountries),
      thunkAPI
    });
  }
);
export const createDeviceAction = createAsyncThunk(
  'devices/create',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createDeviceApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const updateDeviceAction = createAsyncThunk(
  'devices/edit',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createDeviceApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);

export const deleteDeviceAction = createAsyncThunk(
  'devices/delete',
  async ( {dataToBeSubmitted} , thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteDeviceApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
