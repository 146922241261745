import { createAsyncThunk } from '@reduxjs/toolkit';
import customApiRequest from '../../helpers/customApiRequest';
import getAllWebsiteRequestsApi from '../../apis/website-requests-apis/getAllWebsiteRequestsApi';

export const getWebsiteRequestsAction = createAsyncThunk(
  'websiteRequests/getWebsiteRequestsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllWebsiteRequestsApi(filterObj),
      thunkAPI
    });
  }
);

