import { Navigate, Route } from 'react-router-dom';
import DefaultAppLayout from '../components/app/app-layout/DefaultAppLayout';
import routerLinks from '../components/app/routerLinks';
import accountTypes from '../const-values/accountTypes';
const generalRoute = (
  path,
  component,
  key,
  redirectRoute,
  loggedIn,
  user,
  accountTypesArr = [accountTypes()?.user]
) => {
  const renderElement = () => {
    if (
      !user ||
      (user?.accountType &&
        accountTypesArr?.length > 0 &&
        accountTypesArr.includes(user.accountType))
    ) {
      return <DefaultAppLayout>{component}</DefaultAppLayout>;
    } else {
      return <Navigate to={routerLinks?.notFound} />;
    }
  };
  return (
    <Route
      exact
      path={path}
      key={key}
      element={renderElement()}
      // element={
      //   loggedIn ? (
      //     <AppLayout>{component}</AppLayout>
      //   ) : (
      //     <Navigate
      //       to={redirectRoute ? redirectRoute : routerLinks?.signinPage}
      //     />
      //   )
      // }
    />
  );
};

export default generalRoute;
