/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './PackageForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import Xicon from '../../common/icons/Xicon';
import FileInput from '../../common/file-input/FileInput';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import {
  setPackageFormOpenedAction,
} from '../../redux/packages-slice/appPackagesSlice';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import { getPackagesStatusArr } from '../../const-values/packagesStatus';
import {
  createAppPackageAction,
  updateAppPackageAction
} from '../../redux/packages-slice/appPackagesSliceThunkApi';
import { useState } from 'react';
import AntdRadioGroup from '../../common/antd-form-components/AntdRadioGroup';
import { packageTypesArr } from '../../const-values/packageTypes';
import { t } from 'i18next';
import RadioButtonFilled from '../../common/icons/RadioButtonFilled';
import RadioButtonEmpty from '../../common/icons/RadioButtonEmpty';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '../../common/icons/DeleteIcon.jsx';
import PlusIcon from '../../common/icons/PlusIcon.jsx';
import { toast } from 'react-toastify';

const schema = Yup.object().shape({
  name_ar: Yup.string().required('من فضلك ادخل اسم الجهاز بالعربية'),
  name_en: Yup.string().required('من فضلك ادخل اسم الجهاز بالانجليزية'),
  duration_ar: Yup.string().required('من فضلك ادخل مدة الباقة بالعربية'),
  duration_en: Yup.string().required('من فضلك ادخل مدة الباقة بالانجليزية'),
  offlineOrOnline: Yup.string().required('من فضلك اختار نوع الباقه'),
  usersCount: Yup.string()
    .required('من فضلك ادخل عدد مستخدمين الباقة')
    .matches(/^[0-9]+$/, 'ادخل العدد على هيئة ارقام')
    .test('price', 'سعر خاطئ', (v, context) => {
      let result = true;
      if (!v || (v && parseFloat(v) <= 0)) {
        result = false;
      }
      return result;
    }),
  price: Yup.string()
    .required('من فضلك ادخل سعر الباقة')
    .matches(/^(\d+)?(\.\d+)?$/, 'ادخل السعر على هيئة ارقام')
    .test('price', 'سعر خاطئ', (v, context) => {
      let result = true;
      if (!v || (v && parseFloat(v) <= 0)) {
        result = false;
      }
      return result;
    }),
  discount: Yup.string()
    // .required('من فضلك ادخل نسبة الخصم')
    .matches(/^(\d+)?(\.\d+)?$/, 'ادخل الخصم على هيئة ارقام')
    .test('discount', 'خصم غير صحيح',  function (v) {
      const price = parseFloat(this.parent.price); // الوصول إلى السعر من نفس الكائن
      const discount = parseFloat(v);
      if (isNaN(discount)) return true; // إذا لم يتم إدخال خصم، اعتبره صحيحًا
      return discount >= 0 && discount < price; // تحقق من أن الخصم بين 0 وأقل من السعر
    }),
  days: Yup.string()
    .required('من فضلك ادخل عدد ايام الباقة')
    .matches(/^[0-9]+$/, 'ادخل الايام على هيئة ارقام')
    .test('days', 'مدة خاطئة', (v, context) => {
      let result = true;
      if (!v || parseFloat(v) <= 0) result = false;
      return result;
    }),
  status: Yup.string().required('من فضلك اختار حالة الباقة'),
  features: Yup.array()
    .of(
      Yup.object().shape({
        name_ar: Yup.string().required('من فضلك ادخل اسم الخاصية بالعربية'),
        name_en: Yup.string().required('من فضلك ادخل اسم الخاصية بالانجليزية')
      })
    )
    .required('من فضلك ادخل مميزات الباقة')
  // device_id: Yup.string().required('من فضلك اختار الجهاز')
});

const { medalIcon, deviceIcon, fontSizeIcon, imageIcon } = imagesSrcs;

const emptyFormValues = {
  name_ar: '',
  name_en: '',
  offlineOrOnline: '',
  usersCount: '',
  duration_ar: '',
  duration_en: '',
  desc_ar: '',
  desc_en: '',
  days: '',
  price: '',
  discount: '',
  status: '',
  device_id: '',
  // features: [{ name_ar: '', name_en: '' }],
  icon: null
};

const PackageForm = () => {
  const { i18n } = useTranslation();
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.appPackages.selectedTableRow
  );
  const fetchedCountries = useSelector(
    (store) => store.devices.fetchedCountries
  );
  ///////////////
  // brands selectors
  const fetchedDevices = useSelector((store) => store.devices.fetchedDevices);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {...emptyFormValues,   features: selectedTableRow?[]:[{ name_ar: '', name_en: '' }],
    }
  });

  // render option type
  const renderPackagesList = (t) => {
    return (
      packageTypesArr(t)?.length > 0 &&
      packageTypesArr(t).map((obj) => {
        return (
          obj && (
            <label
              key={obj?.id}
              className={`radio-input-label ${i18n.dir()} ${
                String(watch(`offlineOrOnline`)) == String(obj.id)
                  ? 'selected'
                  : ''
              }`}
            >
              <input
                onClick={() => {
                  if (watch('offlineOrOnline') == obj?.value) {
                    setValue('offlineOrOnline', '');
                  }
                }}
                type="radio"
                value={obj?.value}
                {...register(`offlineOrOnline`)}
              />
              {watch(`offlineOrOnline`) == obj?.value ? (
                <RadioButtonFilled />
              ) : (
                <RadioButtonEmpty />
              )}
              <span className="label-span">{obj?.name} </span>
            </label>
          )
        );
      })
    );
  };

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name_ar', selectedTableRow?.name_ar || '');
      setValue('name_en', selectedTableRow?.name_en || '');
      setValue('duration_ar', selectedTableRow?.duration_ar || '');
      setValue('duration_en', selectedTableRow?.duration_en || '');
      setValue('desc_ar', selectedTableRow?.desc_ar || '');
      setValue('desc_en', selectedTableRow?.desc_en || '');
      setValue('days', selectedTableRow?.days || '');
      setValue('price', selectedTableRow?.price || '');
      setValue('usersCount', selectedTableRow?.usersCount || '');
      setValue('country_id', selectedTableRow?.country?.id || '1');
      setValue('discount', selectedTableRow?.discount || '');

      setValue(
        'offlineOrOnline',
        selectedTableRow?.offlineOrOnline
          ? String(selectedTableRow.offlineOrOnline)
          : ''
      );
      // setValue(
      //   'device_id',
      //   selectedTableRow?.device?.id ? String(selectedTableRow.device.id) : ''
      // );
      setValue('status', String(selectedTableRow.status));
      if (selectedTableRow?.features?.length > 0)
        setValue('features', selectedTableRow.features);
    }
  }, [selectedTableRow]);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name_ar', data.name_ar || '');
    formData.append('name_en', data.name_en || '');
    formData.append('offlineOrOnline', data.offlineOrOnline || '');
    formData.append('usersCount', data.usersCount || '');
    formData.append('duration_ar', data.duration_ar || '');
    formData.append('duration_en', data.duration_en || '');
    formData.append('desc_ar', data.desc_ar || '');
    formData.append('desc_en', data.desc_en || '');
    formData.append('price', data.price || '');
    formData.append('days', data.days || '');
    // formData.append('device_id', data.device_id || '');

  
    if(data.discount){ 
      if (Number(data.discount) > Number(data.price) ) {

        toast.error("خصم غير صحيح", {
          toastId: "discount-error", // Unique ID for this toast
        });
        return;
      } 
       formData.append('discount', data.discount || '');
      formData.append('discountType', '1'|| '');
      formData.append('isDiscount', '1'|| '');
    }else{
      formData.append('discount', 0);
      formData.append('discountType',0);
      formData.append('isDiscount',0);
    }
    formData.append('status', data.status || '');
    if (data?.features?.length > 0) {
      formData.append('features', JSON.stringify(data?.features));
    }
    if (data?.icon?.length > 0) {
      formData.append('icon', data.icon[0]);
    }
    let countryId = Number(data?.country_id);
    if (countryId) {
      formData.append('country_id', data?.country_id || '');
    } else {
      const country = fetchedCountries.find(
        (obj) => obj.name === data?.country_id
      );
      formData.append('country_id', country?.id || '');
    }
    if (!selectedTableRow) {
      dispatch(
        createAppPackageAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else {
      formData.append('package_id', selectedTableRow?.id || '');

      dispatch(
        updateAppPackageAction({
          dataToBeSubmitted: formData,
          id: selectedTableRow?.id,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    }
  };

  const [form] = Form.useForm();


  return (
    <Form
      className="custom-shared-form package-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة باقة جديدة
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setPackageFormOpenedAction(false));
          }}
        >
          {/* <ArrowToLeftIcon color="#40404199" /> */}
          <Xicon color="#40404199" />
        </button>
      </p>
      <div className="form-body">
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>إســم الباقة بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_ar"
              type="text"
              placeholder={'إســم الباقة بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>إســم الباقة بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_en"
              type="text"
              placeholder={'إســم الباقة بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.name_en?.message}
              validateStatus={errors?.name_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>مدة الباقة بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="duration_ar"
              type="text"
              placeholder={'مدة الباقة بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.duration_ar?.message}
              validateStatus={errors?.duration_ar ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>مدة الباقة بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="duration_en"
              type="text"
              placeholder={'مدة الباقة بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.duration_en?.message}
              validateStatus={errors?.duration_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>سعر الباقة</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="price"
              type="text"
              placeholder={'سعر الباقة...'}
              // label="الاســــم"
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>قيمة الخصم</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="discount"
              type="text"
              placeholder={'قيمة الخصم...'}
              // label="الاســــم"
              errorMsg={errors?.discount?.message}
              validateStatus={errors?.discount ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}

        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={deviceIcon} alt="name" />
              <span>عدد ايام الباقة</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="days"
              type="text"
              placeholder={'عدد ايام الباقة...'}
              // label="الاســــم"
              errorMsg={errors?.days?.message}
              validateStatus={errors?.days ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper">
            <p className="outside-label">
              <img src={fontSizeIcon} alt="name" />
              ادخل البلد
            </p>
            <AntdSelectOption
              name={`country_id`}
              errorMsg={errors?.country_id && errors.country_id.message}
              validateStatus={errors?.country_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="ادخل البلد"
              options={
                fetchedCountries?.data?.length > 0 &&
                fetchedCountries?.data?.map((obj) => ({
                  title: obj?.name,
                  value: obj?.id
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="device-form"
            />
          </div>
        </div>

        {/*  */}

        <div
          className="radios-wrap"
          style={{
            marginBottom: 22
          }}
        >
          <p
            style={{
              marginTop: 18,
              marginBottom: 5,
              fontSize: 16
            }}
          >
            {t('product_form.productSettings.label')}
          </p>
          <div
            className="labels-wrap need-label"
            style={{
              marginTop: 0
            }}
          >
            {packageTypesArr(t)?.length > 0 && renderPackagesList(t)}
          </div>
          {errors?.offlineOrOnline && (
            <p
              className="error-p"
              style={{
                padding: '0 18px'
              }}
            >
              {errors?.offlineOrOnline?.message}
            </p>
          )}
        </div>

        {/*  */}

        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>عدد مستخدمين الباقه</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="usersCount"
              type="text"
              placeholder={' عدد مستخدمين الباقه...'}
              // label="الاســــم"
              errorMsg={errors?.usersCount?.message}
              validateStatus={errors?.usersCount ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="select-label-wrap shared-app-select-option-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              حالة الباقة
            </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`status`}
                errorMsg={errors?.status && errors.status.message}
                validateStatus={errors?.status ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="حالة الباقة"
                options={
                  getPackagesStatusArr()?.length > 0 &&
                  getPackagesStatusArr().map((obj) => ({
                    title: obj?.name,
                    value: obj?.value
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="package-form"
              />
            </div>
          </div>

          {/*  */}
        </div>

        <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option feature-inputs">
          <p className="outside-label">
            <img src={fontSizeIcon} alt="name" />
            مميزات الباقة
          </p>

          {watch('features')?.map((feature, index) => (
            <div key={index} className="feature-input-group">
              <div className="two-cols-row">
                <AntdTextField
                  name={`features[${index}].name_ar`}
                  type="text"
                  placeholder="اسم الخاصية بالعربية"
                  errorMsg={errors?.features?.[index]?.name_ar?.message}
                  validateStatus={
                    errors?.features?.[index]?.name_ar ? 'error' : ''
                  }
                  // defaultValue={feature.name_ar}
                  control={control}
                />
                <AntdTextField
                  name={`features[${index}].name_en`}
                  type="text"
                  placeholder="Feature name in English"
                  errorMsg={errors?.features?.[index]?.name_en?.message}
                  validateStatus={
                    errors?.features?.[index]?.name_en ? 'error' : ''
                  }
                  // defaultValue={feature.name_en}

                  control={control}
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  const updatedFeatures = watch('features').filter(
                    (_, i) => i !== index
                  );
                  setValue('features', updatedFeatures);
                }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}

          <button
            type="button"
            className="plus-icon"
            onClick={() => {
              setValue('features', [
                ...watch('features'),
                { name_ar: '', name_en: '' }
              ]);
            }}
          >
            <PlusIcon />
          </button>
        </div>
        {/*  */}

        {/* DESC */}
        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>تفاصيل الباقة بالعربية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="desc_ar"
            type="text"
            placeholder={'تفاصيل الباقة بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.desc_ar?.message}
            validateStatus={errors?.desc_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>تفاصيل الباقة بالانجليزية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="desc_en"
            type="text"
            placeholder={'تفاصيل الباقة بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.desc_en?.message}
            validateStatus={errors?.desc_en ? 'error' : ''}
            control={control}
          />
        </div>
        {/* END DESC */}

        <div className="file-inputs-wrap">
          <FileInput
            name="icon"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imageIcon} alt="name" />
                صورة الجهاز
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.icon ? [{ url: selectedTableRow.icon }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.icon?.message}
            files={files}
            setFiles={setFiles}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(setPackageFormOpenedAction(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>
    </Form>
  );
};

export default PackageForm;
