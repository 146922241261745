import { createSlice } from '@reduxjs/toolkit';
import { getWebsiteRequestsAction } from './websiteRequestsSliceThunkApi';

const initialState = {
  /////////////////////
  isLoadingTableData: false,
  fetchedTableData: [],
  fetchedSingleInfluencer: null,

  fetchDataCount: 0,

  isSubmitting: false,

  selectedInfluencerInvoice: null,

  //
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const  websiteRequestsSlice = createSlice({
  name: ' websiteRequestsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // get  websiteRequests
    [getWebsiteRequestsAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getWebsiteRequestsAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data?.data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getWebsiteRequestsAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    }
    // get single client
  }
});

const  websiteRequestsReducer =  websiteRequestsSlice.reducer;

export const {
 
} =  websiteRequestsSlice.actions;

export default  websiteRequestsReducer;
