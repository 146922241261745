/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './AdminFormPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getSingleAdminAction } from '../../redux/settings-slice/settingsSliceThunkApi';
import AdminForm from './AdminForm';
import ArrowToLeftIcon from '../../common/icons/ArrowToLeftIcon';
import CustomLoader from '../../common/custom-loader/CustomLoader';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const AdminFormPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const fetchedSingleAdmin = useSelector(
    (store) => store.settings.fetchedSingleAdmin
  );
  const isSubmitting = useSelector((store) => store.settings.isSubmitting);
  const isLoadingTableData = useSelector(
    (store) => store.settings.isLoadingTableData
  );

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        getSingleAdminAction({
          filterObj: {
            admin_id: params?.adminId || ''
          }
        })
      );
    }
    return () => {
      isMounted = false;
    };
  }, [pathname]);

  if (isLoadingTableData) {
    return <CustomLoader />;
  }

  return (
    <div className="admin-form-page">
      <p className="page-title">مستخدمي لوحة تحكم myCASH</p>

      <div className="page-content-wrapper">
        <div className="page-main-content-wrapper">
          <div className="admin-form-wrapper">
            <p className="form-title">
              {fetchedSingleAdmin
                ? 'تعديل بيانات المستخدم'
                : 'إضافة مستخدم جديد'}
              <button
                type="button"
                className="back-btn"
                onClick={() => navigate(-1)}
              >
                {/* <ArrowToLeftIcon color="#40404199" /> */}
                <ArrowToLeftIcon color="#40404199" />
              </button>
            </p>
            <AdminForm />
          </div>
        </div>
      </div>

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default AdminFormPage;
