import axios from 'axios';
import apisUrls from '../urls';

const getSinleInfluencerApi = (paramsFilter) => {
  return axios.get(
    `${apisUrls?.getSingleInfluencer}?influencer_id=${
      paramsFilter?.influencer_id || ''
    }`
  );
};

export default getSinleInfluencerApi;
