import { createAsyncThunk } from '@reduxjs/toolkit';
import changeWebsiteServiceStatusApi from '../../apis/website-services-apis/changeWebsiteServiceStatusApi';
import createWebsiteServiceApi from '../../apis/website-services-apis/createWebsiteServiceApi';
import deleteWebsiteServiceApi from '../../apis/website-services-apis/deleteWebsiteServiceApi';
import getSingleWebsiteServiceApi from '../../apis/website-services-apis/getSingleWebsiteServiceApi';
import getWebsiteServicesApi from '../../apis/website-services-apis/getWebsiteServicesApi';
import getWebsiteServicesFeaturesApi from '../../apis/website-services-apis/getWebsiteServicesFeaturesApi';
import customApiRequest from '../../helpers/customApiRequest';

export const getWebsiteServicesAction = createAsyncThunk(
  'websiteServices/getWebsiteServicesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getWebsiteServicesApi(filterObj),
      thunkAPI
    });
  }
);

export const getWebsiteServicesFeaturesAction = createAsyncThunk(
  'websiteServices/getWebsiteServicesFeaturesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getWebsiteServicesFeaturesApi(filterObj),
      thunkAPI
    });
  }
);

export const getSingleWebsiteServiceAction = createAsyncThunk(
  'websiteServices/getSingleWebsiteServiceAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSingleWebsiteServiceApi(filterObj),
      thunkAPI
    });
  }
);

// create website service
export const createWebsiteServiceAction = createAsyncThunk(
  'websiteServices/createWebsiteServiceAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWebsiteServiceApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//
// update website service
export const updateWebsiteServiceAction = createAsyncThunk(
  'websiteServices/updateWebsiteServiceAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWebsiteServiceApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);
//
// change website service status
export const changeWebsiteServiceStatusAction = createAsyncThunk(
  'websiteServices/changeWebsiteServiceStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeWebsiteServiceStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//

export const deleteWebsiteServiceAction = createAsyncThunk(
  'websiteServices/deleteWebsiteServiceAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteWebsiteServiceApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
