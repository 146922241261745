import axios from 'axios';
import apisUrls from '../urls';

export const createAppGuideApi = (values, edit = false) => {
  const url = !edit
    ? apisUrls?.createAppGuiderUrl
    : apisUrls?.updateAppGuiderUrl;

  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteAppGuideApi = (values) => {
  return axios.post(apisUrls?.deleteAppGuiderUrl, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getAllAppGuideApi = (filterObj) => {
  return axios.get(apisUrls?.getAppGuides(filterObj));
};

export const getSingleAppGuideApi = (filterObj) => {
  return axios.get(apisUrls?.getSingleAppGuideUrl(filterObj));
};
