import { Navigate, Route } from 'react-router-dom';
import DefaultAppLayout from '../components/app/app-layout/DefaultAppLayout';
import routerLinks from '../components/app/routerLinks';
const protectMe = (
  path,
  component,
  key,
  redirectRoute,
  loggedIn,
  user,
  accountTypesArr,
  withLayout = true,
  Children,
  authed
) => {
  const renderElement = () => {
    if (authed) {
      if (withLayout) {
        return <DefaultAppLayout>{component}</DefaultAppLayout>;
      }
      return component;
    } else if (loggedIn && user?.activated) {
      // return <DefaultAppLayout>{component}</DefaultAppLayout>;
      if (
        accountTypesArr?.length > 0 &&
        accountTypesArr.includes(user?.accountType)
      ) {
        if (withLayout) {
          return <DefaultAppLayout>{component}</DefaultAppLayout>;
        }
        return component;
      } else {
        return <Navigate to={routerLinks?.notFound} />;
      }
    }

    return (
      <Navigate to={redirectRoute ? redirectRoute : routerLinks?.signinPage} />
    );
  };

  return (
    <Route
      exact
      path={path}
      key={key}
      element={renderElement()}
      // element={
      //   loggedIn && user?.activated ? (
      //     accountTypesArr?.length > 0 &&
      //     accountTypesArr.includes(user?.accountType) ? (
      //       <DefaultAppLayout>{component}</DefaultAppLayout>
      //     ) : (
      //       <Navigate to={routerLinks?.notFound} />
      //     )
      //   ) : (
      //     <Navigate
      //       to={redirectRoute ? redirectRoute : routerLinks?.signinPage}
      //     />
      //   )
      // }
    >
      {Children}
    </Route>
    // <Route
    //   exact
    //   path={path}
    //   key={key}
    //   element={
    //     loggedIn && user?.activated ? (
    //       <DefaultAppLayout>{component}</DefaultAppLayout>
    //     ) : (
    //       <Navigate
    //         to={redirectRoute ? redirectRoute : routerLinks?.signinPage}
    //       />
    //     )
    //   }
    // />
  );
};

export default protectMe;
