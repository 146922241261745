import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  getSectionTitlesAction,
  getSingleSectionTitlesAction,
  updateSectionTitleAction
} from './SectionTitlesSliceThunkApi';

const initialState = {
  //
  isLoadingTitles: false,
  fetchedTitles: [],
  //
  //
  //

  isLoadingSingleTitle: false,
  fetchedSingleTitle: '',
  //
  isSubmitting: false,
  fetchAllDataCount: 0,
  fetchSingleCount: 0
};

const sectionTitlesSlice = createSlice({
  name: 'sectionTitles',
  initialState,
  reducers: {
    setFetchedSingleTitle: (sliceState, action) => {
      sliceState.fetchedSingleTitle = action.payload;
    }
  },
  //
  extraReducers: {
    // get all
    [getSectionTitlesAction.pending]: (state) => {
      state.isLoadingTitles = true;
    },
    [getSectionTitlesAction.fulfilled]: (state, action) => {
      state.isLoadingTitles = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTitles = data;
    },
    [getSectionTitlesAction.rejected]: (state) => {
      state.isLoadingTitles = false;
    },
    // get single
    [getSingleSectionTitlesAction.pending]: (state) => {
      state.isLoadingSingleTitle = true;
    },
    [getSingleSectionTitlesAction.fulfilled]: (state, action) => {
      state.isLoadingSingleTitle = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSingleTitle = data;
    },
    [getSingleSectionTitlesAction.rejected]: (state) => {
      state.isLoadingSingleTitle = false;
    },
    // update single
    [updateSectionTitleAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateSectionTitleAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action?.payload?.res;
      if (checkRes(res)) {
        state.fetchSingleCount += 1;
      }
    },
    [updateSectionTitleAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
  }
});

const sectionTitlesReducer = sectionTitlesSlice.reducer;

export const { setFetchedSingleTitle } = sectionTitlesSlice.actions;

export default sectionTitlesReducer;
