import React, { useContext } from 'react';
import UserContext from '../contexts/user-context/UserProvider';
import { useNavigate } from 'react-router-dom';
import routerLinks from '../components/app/routerLinks';
import useCustomApiRequest from './useCustomApiRequest';
import checkRes from '../utils/checkRes';
import successNotification from '../utils/successNotification';
import errorNotification from '../utils/errorNotification';
import signoutApi from '../apis/auth/signoutApi';
import { useTranslation } from 'react-i18next';

const useSignout = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user, removeCurrentUser } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const [isLoadingSignout, setIsLoadingSignout] = React.useState(false);
  const signMeOut = () => {
    setIsLoadingSignout(true);
    customApiRequest(
      signoutApi(user?.token),
      (res) => {
        setIsLoadingSignout(false);
        if (checkRes) {
          removeCurrentUser();
          // successNotification({
          //   title: t('successTitle'),
          //   message:
          //     i18n.language === 'ar'
          //       ? 'تم تسجيل الخروج بنجاح'
          //       : 'Logged out successfully'
          // });
          //
          navigate(routerLinks?.signinPage);
        }
        // else {
        //   errorNotification({
        //     title: t('errorTitle'),
        //     message: res?.data?.message || t('signoutForm.error')
        //   });
        // }
      }

      // (error) => {
      //   setIsLoadingSignout(false);
      //   errorNotification({
      //     title: t('errorTitle'),
      //     message: error?.response?.data?.message || t('signoutForm.error')
      //   });
      // }
    );
  };

  return { signMeOut, isLoadingSignout, setIsLoadingSignout };
};

export default useSignout;
