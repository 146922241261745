import axios from 'axios';
import apisUrls from '../urls';

const deleteWebsiteServiceApi = (values) => {
  return axios.post(apisUrls?.deleteWebsiteServiceUrl, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default deleteWebsiteServiceApi;
