import React from 'react';
import { useTranslation } from 'react-i18next';
import MainAppBar from '../../main-app-bar/MainAppBar';
import './DefaultAppLayout.scss';
import ActiveAppBarLink from '../../../utils/ActiveAppBarLink';
import DocTitleScrollTop from '../../../utils/DocTitleScrollTop';
import { useLocation } from 'react-router-dom';
import TopAppbar from './TopAppbar';
import { useMediaQuery } from 'react-responsive';

const DefaultAppLayout = ({ children }) => {
  const { i18n } = useTranslation();
  // const { Header, Content, Footer, Sider } = AntdLayout;
  ActiveAppBarLink();
  DocTitleScrollTop();
  const { pathname } = useLocation();
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });
  return (
    <div
      className={`app-layout app-${i18n.dir()} ${
        pathname.includes('settings/help') ? 'in-help-page' : ''
      }`}
    >
      {/* <div className="custom-container"> */}
      <div className="layout-content">
        {!pathname.includes('invoice-details') &&
          !pathname.includes('settings/help') && <MainAppBar />}

        {isDesktop ? (
          <div className="custom-container-wrapper">
            <div className="custom-container">
              <div className="top-appbar-children-wrapper">
                <TopAppbar />
                {children}
              </div>
            </div>
          </div>
        ) : (
          <div className="custom-container-wrapper">
            <div className="top-appbar-children-wrapper">
              <div
                style={{
                  backgroundColor: isDesktop ? 'transparent' : '#eee'
                }}
              >
                <div className="custom-container">
                  <TopAppbar />
                </div>
              </div>
              <div className="custom-container">{children}</div>
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </div>
  );
};

export default DefaultAppLayout;
