/* eslint-disable react-hooks/exhaustive-deps */
import React, { useTransition } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { getBrandsStatusArr } from '../../const-values/brandsStatus';
import { useEffect } from 'react';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import EyeOffIcon from '../../common/icons/EyeOffIcon';
import AntdCheckboxsGroup from '../../common/antd-form-components/AntdCheckboxsGroup';
import {
  createAdminAction,
  updateAdminAction
} from '../../redux/settings-slice/settingsSliceThunkApi';
import { getAdminRolesArr } from '../../const-values/adminRoles';

const schema = Yup.object().shape({
  name: Yup.string().required('من فضلك ادخل اسم المستخدم'),
  phone: Yup.string()
    .required('من فضلك ادخل رقم هاتف العميل')
    .matches(/^[0-9]+$/, 'رقم هاتف خاطئ')
    .length(9, 'رقم هاتف يجب ان يكون 9 ارقام'),
  email: Yup.string()
    .required('من فضلك ادخل البريد الالكترونى')
    .email('بريد الكترونى خاطئ'),
  password: Yup.string()
    .required('من فضلك ادخل كلمة المرور')
    .min(6, 'أقل حد 8 حروف'),
  password_confirm: Yup.string()
    .required('من فضلك اعد كتابة كلمة المرور')
    .oneOf([Yup.ref('password')], 'كلمة مرور خاطئة'),
  status: Yup.string().required('من فضلك اختار حالة المستخدم')
});

const { medalIcon } = imagesSrcs;

const AdminForm = () => {
  const fetchedSingleAdmin = useSelector(
    (store) => store.settings.fetchedSingleAdmin
  );
  const dispatch = useDispatch();

  const emptyFormValues = {
    name: '',
    phone: '',
    email: '',
    password: '',
    password_confirm: '',
    status: '',
    roles: []
  };
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ...emptyFormValues
    }
  });

  // handle initial values
  useEffect(() => {
    if (fetchedSingleAdmin) {
      setValue('name', fetchedSingleAdmin?.name || '');
      setValue('phone', fetchedSingleAdmin?.phone || '');
      setValue('email', fetchedSingleAdmin?.email || '');
      setValue(
        'status',
        fetchedSingleAdmin?.status ? String(fetchedSingleAdmin?.status) : ''
      );
      if (fetchedSingleAdmin?.roles) {
        const arr = fetchedSingleAdmin.roles.split(',');
        setValue(
          'roles',
          arr.map((v) => Number(v))
        );
      }
    } else {
      reset(emptyFormValues);
    }
  }, [fetchedSingleAdmin]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name', data.name || '');
    formData.append('phone', data.phone || '');
    formData.append('email', data.email || '');
    formData.append('password', data.password || '');
    formData.append('status', data.status || '');
    if (data.roles?.length > 0) {
      formData.append('roles', data.roles.join(','));
    }
    if (!fetchedSingleAdmin) {
      dispatch(
        createAdminAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else {
      formData.append('admin_id', fetchedSingleAdmin?.id);
      dispatch(
        updateAdminAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    }
  };

  const [passwrodVisible, setPasswordVisible] = React.useState(false);

  // console.log('watch : ', watch());

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form admin-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      fields={[
        {
          name: 'roles',
          value: watch('roles')
        }
      ]}
    >
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={imagesSrcs?.userIcon} alt="name" />
            <span>اسم المستخدم</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name"
            type="text"
            placeholder={'اسم المستخدم...'}
            // label="الاســــم"
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={imagesSrcs?.phoneIcon} alt="name" />
            <span>رقم هاتف المستخدم</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="phone"
            type="text"
            placeholder={'رقم هاتف المستخدم...'}
            // label="الاســــم"
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={imagesSrcs?.emailIcon} alt="name" />
            <span>البريد الالكترونى</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="email"
            type="text"
            placeholder={'البريد الالكترونى...'}
            // label="الاســــم"
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={imagesSrcs?.lockIcon} alt="lock" />
            <span>كلمة المرور</span>
          </p>
          <div className="shared-form-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="password"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder="كلمة المرور..."
              errorMsg={errors?.password?.message}
              validateStatus={errors?.password ? 'error' : ''}
              control={control}
            />

            {watch('password') && (
              <div
                className="eye-icon-btn"
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </div>
            )}
          </div>
        </div>

        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={imagesSrcs?.lockIcon} alt="lock" />
            <span>تأكيد كلمة المرور</span>
          </p>
          <div className="shared-form-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="password_confirm"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder="تأكيد كلمة المرور..."
              errorMsg={errors?.password_confirm?.message}
              validateStatus={errors?.password_confirm ? 'error' : ''}
              control={control}
            />

            {watch('password') && (
              <div
                className="eye-icon-btn"
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </div>
            )}
          </div>
        </div>

        <div className="select-label-wrap shared-app-select-option-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            حالة المستخدم
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`status`}
              errorMsg={errors?.status && errors.status.message}
              validateStatus={errors?.status ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="حالة المستخدم"
              options={
                getBrandsStatusArr()?.length > 0 &&
                getBrandsStatusArr().map((obj) => ({
                  title: obj?.name,
                  value: obj?.id
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="admin-form"
            />
          </div>
        </div>

        {/* <div className="roles-checkboxs">
          <AntdCheckboxsGroup
            className="admins-roles"
            name="roles"
            label={
              <p>
                <img src={imagesSrcs?.medalIcon} alt="roles" />
                صلاحيات المستخدم
              </p>
            }
            // onChange={() => setValue('model_id', '')}
            control={control}
            validateStatus={errors?.roles ? 'error' : ''}
            errorMsg={errors?.roles?.message}
            options={
              getAdminRolesArr(t)?.length > 0
                ? getAdminRolesArr(t).map((item) => {
                    return {
                      label: item?.name,
                      value: item?.id
                    };
                  })
                : []
            }
            defaultValue={
              fetchedSingleAdmin?.roles?.length > 0
                ? fetchedSingleAdmin.roles
                : []
            }
          />
        </div> */}

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            {fetchedSingleAdmin ? 'تعديل' : 'حفظ'}
          </button>
          <button
            type="button"
            onClick={() => {
              if (!fetchedSingleAdmin) reset(emptyFormValues);
              else
                reset({
                  name: fetchedSingleAdmin?.name || '',
                  phone: fetchedSingleAdmin?.phone || '',
                  email: fetchedSingleAdmin?.email || '',
                  status: String(fetchedSingleAdmin?.status) || '',
                  roles: fetchedSingleAdmin?.roles
                    ? fetchedSingleAdmin.roles.split(',').map((v) => Number(v))
                    : []
                });
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default AdminForm;
