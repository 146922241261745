/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SharedAntdTable from '../../common/shared-antd-table/SharedAntdTable';
import UserContext from '../../contexts/user-context/UserProvider';
import {
  setPostFormModalOpened,
  setSelectedTableRow
} from '../../redux/payment-types-slice/paymentTypesSlice';
import PaymentTypesTableColumns from './PaymentTypesTableColumns';

const PaymentTypePageTable = () => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const fetchedTableData = useSelector(
    (store) => store.paymentTypes.fetchedTableData
  );
  const isLoadingTableData = useSelector(
    (store) => store.paymentTypes.isLoadingTableData
  );

  // map all data to dataSource
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  useEffect(() => {
    let isMounted = true;

    if (fetchedTableData?.length >= 0) {
      const mappedData = mapDataSource(fetchedTableData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchedTableData]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <SharedAntdTable
      scroll={700}
      showHeader={true}
      className={`recent-clients-table ${i18n.dir()}`}
      hasPagination={true}
      tableDataSource={tableDataSource}
      tableColumns={PaymentTypesTableColumns}
      allFetchedTableData={fetchedTableData}
      setSelectedTableRow={(v) => dispatch(setSelectedTableRow(v))}
      setFormModalOpened={(v) => dispatch(setPostFormModalOpened(v))}
      //
      isLoadingTableData={isLoadingTableData}
      user={user}
      // tablePagination={tablePagination}
      // baseTablePageUrl={`${sharedRouterLinks?.appArchivesRoute}?package_id=${
      //   values?.package_id || ''
      // }&searchText=${values?.searchText || ''}&sort=${
      //   values?.sort || ''
      // }&archive=1`}
    />
  );
};

export default PaymentTypePageTable;
