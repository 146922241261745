import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createAppGuideAction,
  deleteAppGuideAction,
  getSingleAppGuideAction,
  getAppGuideAction,
  updateAppGuideAction
} from './appGuideSliceThunkApi';

const initialState = {
  /////////////////////
  fetchedSingleGuide: null,
  isLoadingTableData: false,
  fetchedTableData: [],
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  },
  fetchDataCount: 0,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  confirmModal: {
    opened: false,
    type: ''
  }
};

const appGuideSlice = createSlice({
  name: 'appGuideSlice',
  initialState,
  reducers: {
    setFetchDataCount: (sliceState, action) => {
      sliceState.fetchDataCount = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setConfirmModal: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    }
  },
  extraReducers: {
    // get guides
    [getAppGuideAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getAppGuideAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getAppGuideAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    // get single guide
    [getSingleAppGuideAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getSingleAppGuideAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSingleGuide = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getSingleAppGuideAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    // create guide
    [createAppGuideAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createAppGuideAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [createAppGuideAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // update guide
    [updateAppGuideAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateAppGuideAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [updateAppGuideAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    // delete guide
    [deleteAppGuideAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteAppGuideAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteAppGuideAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const appGuideReducer = appGuideSlice.reducer;

export const {
  setPostFormModalOpened,
  setFetchDataCount,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setSelectedTab
} = appGuideSlice.actions;

export default appGuideReducer;
