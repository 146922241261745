import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  changeWebsitePackageStatusAction,
  createWebsitePackageAction,
  deleteWebsitePackageAction,
  getSingleWebsitePackageAction,
  getWebsitePackagessAction,
  updateWebsitePackageAction
} from './websitePackagesSliceThunkApi';

const initialState = {
  /////////////////////
  fetchedSinglePackage: null,
  isLoadingTableData: false,
  fetchedTableData: [],
  fetchDataCount: 0,
  websitePackageFormOpened: false,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  confirmModal: {
    opened: false,
    type: ''
  }
};

const websitePackagesSlice = createSlice({
  name: 'websitePackagesSlice',
  initialState,
  reducers: {
    setWebsitePackageFormOpenedAction: (sliceState, action) => {
      sliceState.websitePackageFormOpened = action.payload;
    },
    ////////////////////
    setFetchDataCount: (sliceState, action) => {
      sliceState.fetchDataCount = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setConfirmModal: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    }
    /////////////////////////
  },
  extraReducers: {
    // get packages
    [getWebsitePackagessAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getWebsitePackagessAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getWebsitePackagessAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    // get single package
    [getSingleWebsitePackageAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getSingleWebsitePackageAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSinglePackage = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getSingleWebsitePackageAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    // change package status
    [changeWebsitePackageStatusAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [changeWebsitePackageStatusAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.confirmModal = {
          opened: false,
          type: ''
        };
      }
    },
    [changeWebsitePackageStatusAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // create package
    [createWebsitePackageAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createWebsitePackageAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [createWebsitePackageAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // update package
    [updateWebsitePackageAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateWebsitePackageAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [updateWebsitePackageAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    // delete package
    [deleteWebsitePackageAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteWebsitePackageAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteWebsitePackageAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const websitePackagesReducer = websitePackagesSlice.reducer;

export const {
  setFetchDataCount,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setWebsitePackageFormOpenedAction
} = websitePackagesSlice.actions;

export default websitePackagesReducer;
