/* eslint-disable react-hooks/exhaustive-deps */
import './WebsiteRequestsPage.scss';

import { AnimatePresence, motion } from 'framer-motion';
import WebsiteRequestsPageFilter from './WebsiteRequestsPageFilter';
import WebsiteRequestsPageTable from './WebsiteRequestsPageTable';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useEffect } from 'react';
import { getWebsiteRequestsAction } from '../../redux/website-requests-slice/websiteRequestsSliceThunkApi';

const WebsiteRequestsPage = () => {
    const dispatch = useDispatch();
      const { search } = useLocation();
      const values = queryString.parse(search);
      const fetchDataCount = useSelector(
        (store) => store.websiteRequests.fetchDataCount
      );
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
      let isMounted = true;
      useEffect(() => {
        if (isMounted)
        
        return function () {
          isMounted = false;
        };
      }, []);
    
      useEffect(() => {
        if (isMounted)
          dispatch(
            getWebsiteRequestsAction({
              filterObj: values
            })
          );
    
        return function () {
          isMounted = false;
        };
      }, [fetchDataCount, search]);
    
     
    return(
        <div className="influencers-page">
        <div className={`page-content-influencers-wrapper`}>
          <div>
            <AnimatePresence>
              
                <motion.div
                  // layout
                  initial={{
                    height: 0,
                    opacity: 0,
                    translateX: 700,
                    transition: {
                      opacity: { ease: 'linear', duration: 0.3 },
                      translateX: { ease: 'linear', duration: 0.3 }
                    }
                  }}
                  animate={{
                    height: 'auto',
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      delay: 0.7,
                      opacity: { ease: 'linear', duration: 0.3 },
                      translateX: { ease: 'linear', duration: 0.3 }
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: 700,
                    transition: {
                      opacity: { ease: 'linear', duration: 0.3 },
                      translateX: { ease: 'linear', duration: 0.3 }
                    }
                  }}
                  className="page-main-content"
                >
                  <div className="page-title">المسوقين</div>
                  <WebsiteRequestsPageFilter />
                  <WebsiteRequestsPageTable />
                </motion.div>
     
            </AnimatePresence>
  
          </div>
        </div>
  
       
  
        
      </div>
    )
}
export default WebsiteRequestsPage;