import CustomImage from '../../common/custom-image/CustomImage';
import NotesIcon from '../../common/icons/NotesIcon';
import SmillesIcon from '../../common/icons/SmillesIcon';
import UsersSettingsIcon from '../../common/icons/UsersSettingsIcon';
import calenderImg from '../../assets/imgs/profile-images/calendar-icon.svg';
import instagramImg from '../../assets/imgs/profile-images/instagram.svg';
import mobileImg from '../../assets/imgs/profile-images/mobile-icon.svg';
import mailImg from '../../assets/imgs/profile-images/mail-icon.svg';
import youtubeImg from '../../assets/imgs/profile-images/youtube-icon.svg';
import snapchatImg from '../../assets/imgs/profile-images/snapchat.svg';
import './ProfileSection.scss';
import { useContext } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import imagesSrcs from '../../helpers/imagesSrcs';
import { Link } from 'react-router-dom';
import sharedRouterLinks from '../../components/app/shared-routes/sharedRouterLinks';

const BoxStat = ({ icon, value, title }) => {
  return (
    <div className="box-stat">
      <div className="icon-wrap">{icon}</div>
      <div className="value-wrap">{value}</div>
      <div className="title-wrap">{title}</div>
    </div>
  );
};

const SingleInfo = ({ img, value }) => {
  return (
    <div className="single-info-box">
      <img src={img} alt="info" />
      <p>{value}</p>
    </div>
  );
};

const ProfileInfoSection = () => {
  const { user } = useContext(UserContext);
  console.log(user);
  
  return (
    <div className="profile-info-stats-sections">
      <section className="stats-section">
        <CustomImage
          className="profile-img"
          // src="/assets/imgs/logo/logo-black.svg"
          src={user?.image}
        />
        <div className="stats-wrapper">
          <div>
            <div className="name-email-edit-wrapper">
              <p className="name">{user?.name}</p>
              <p className="email">{user?.email}</p>
            </div>

            <div className="stats-boxs">
              <BoxStat
                icon={<UsersSettingsIcon />}
                value={user?.statistics?.clients || '-'}
                title="عميل"
              />
              <BoxStat
                icon={<SmillesIcon />}
                value={user?.statistics?.codes || '-'}
                title="مسوق"
              />
              <BoxStat
                icon={<NotesIcon />}
                value={user?.statistics?.devices || '-'}
                title="جهاز مسجل"
              />
            </div>
          </div>

          <Link className="edit-link" to={sharedRouterLinks?.editProfileRoute}>
            <img src={imagesSrcs?.editIcon} alt="edit" />
          </Link>
        </div>
      </section>

      <section className="profile-info-section">
        <h4>بيانات الحساب</h4>

        <div className="infos-wrapper">
          <SingleInfo img={calenderImg} value={user?.created_at || '-'} />
          <SingleInfo img={mobileImg} value={user?.phone || '-'} />
          <SingleInfo img={mailImg} value={user?.email || '-'} />
          {/* <SingleInfo img={youtubeImg} value="asjdflk" />
          <SingleInfo img={snapchatImg} value="lkskadjf" />
          <SingleInfo img={instagramImg} value="lkskajdjf" /> */}
        </div>
      </section>
    </div>
  );
};

export default ProfileInfoSection;
