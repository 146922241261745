import React, { useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import useGetData from '../../../custom-hooks/useGetData';
import PdfDocument from '../../blocks/PdfDocument';
import './InvoicePublic.scss';
import { useState } from 'react';
import { useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const InvoicePublic = () => {
  const { client_id, invoice_id } = useParams();

  const { data, loading, error } = useGetData(
    `/client_public/single?user_id=${client_id}&invoice_id=${invoice_id}`
  );

  const svgRef = useRef(null);
  const [pngImage, setPngImage] = useState('');
  useEffect(() => {
    const svgElement = svgRef.current;

    if (svgElement) {
      // Create a new Image object
      const img = new Image();

      // Set the source of the Image to the SVG content
      img.src = `data:image/svg+xml;base64,${btoa(
        new XMLSerializer().serializeToString(svgElement)
      )}`;

      // Wait for the Image to load
      img.onload = () => {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        canvas.width = svgElement.width.baseVal.value;
        canvas.height = svgElement.height.baseVal.value;

        // Draw the SVG on the canvas
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);

        // Convert the canvas to a data URL with PNG format
        const pngUrl = canvas.toDataURL('image/png');

        // Set the PNG data URL as the state
        setPngImage(pngUrl);
      };
    }
  }, [data]);

  const zodicRef = useRef(null);
  const [zodicImage, setZodicImage] = useState('');

  useEffect(() => {
    const svgElement = zodicRef.current;

    if (svgElement) {
      // Create a new Image object
      const img = new Image();

      // Set the source of the Image to the SVG content
      img.src = `data:image/svg+xml;base64,${btoa(
        new XMLSerializer().serializeToString(svgElement)
      )}`;

      // Wait for the Image to load
      img.onload = () => {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        canvas.width = svgElement.width.baseVal.value;
        canvas.height = svgElement.height.baseVal.value;

        // Draw the SVG on the canvas
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);

        // Convert the canvas to a data URL with PNG format
        const pngUrl = canvas.toDataURL('image/png');

        // Set the PNG data URL as the state
        setZodicImage(pngUrl);
      };
    }
  }, [data]);

  console.log('zakat', data?.invoice?.zakatQr);

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 1000
        }}
        className="loading-wrapper"
      >
        <LoadingOutlined style={{ fontSize: 48 }} spin />
      </div>
    );

  return (
    <div className="invoice-public__container">
      {data && pngImage && (
        <PdfDocument
          modalData={data?.invoice}
          parentInfo={data?.clientInfo}
          imageUrl={pngImage}
          zakatQr={zodicImage}
        />
      )}
      <div
        style={{
          opacity: '0',
          position: 'absolute',
          top: '50%',
          left: '0'
        }}
      >
        <QRCode
          ref={svgRef}
          value={`https://dashboard.mycash.sa/client_public/${client_id}/${invoice_id}`}
          style={{
            height: '200px',
            width: '100%'
          }}
        />
        {data?.invoice?.zakatQr && (
          <QRCode
            ref={zodicRef}
            value={data?.invoice?.zakatQr}
            style={{
              height: '200px',
              width: '100%'
            }}
          />
        )}
      </div>
    </div>
  );
};

export default InvoicePublic;
