/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './BrandForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import { setBrandFormOpenedAction } from '../../redux/brands-slice/brandsSlice';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { getBrandsStatusArr } from '../../const-values/brandsStatus';
import {
  createBrandAction,
  editBrandAction
} from '../../redux/brands-slice/brandsSliceThunkApis';
import { useEffect } from 'react';
import FileInput from '../../common/file-input/FileInput';

const schema = Yup.object().shape({
  name_ar: Yup.string().required('من فضلك ادخل الاسم باللغة العربية'),
  name_en: Yup.string().required('من فضلك ادخل الاسم باللغة الانجليزية'),
  status: Yup.string().required('من فضلك اختار حالة البراند')
});

const { medalIcon, deviceIcon, imageIcon } = imagesSrcs;

const BrandForm = () => {
  const dispatch = useDispatch();
  const selectedBrandTableRow = useSelector(
    (store) => store.brands.selectedTableRow
  );

  const emptyFormValues = {
    name_ar: '',
    name_en: '',
    status: '',
    icon: '',
    country_id:"1"
  };
  const [files, setFiles] = React.useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    register,
    unregister,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ...emptyFormValues
    }
  });

  // handle initial values
  useEffect(() => {
    if (selectedBrandTableRow) {
      setValue('name_ar', selectedBrandTableRow?.name_ar);
      setValue('name_en', selectedBrandTableRow?.name_en);
      setValue('country_id', "1"|| '');
      setValue(
        'status',
        selectedBrandTableRow?.status
          ? String(selectedBrandTableRow?.status)
          : ''
      );
      setValue('icon', selectedBrandTableRow?.icon);
    }
  }, [selectedBrandTableRow]);

  const onSubmit = (data) => {
    // console.log(data);
    data.country_id="1"
    if (!selectedBrandTableRow) {
      dispatch(
        createBrandAction({
          dataToBeSubmitted: data,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else
      dispatch(
        editBrandAction({
          dataToBeSubmitted: {
            ...data,
            brand_id: selectedBrandTableRow?.id || ''
          },
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form brand-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة براند جديد
        {/* <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setDeviceFormOpenedAction(false));
          }}
        >
          <Xicon color="#40404199" />
        </button> */}
      </p>
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>اسم البراند بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name_ar"
            type="text"
            placeholder={'اسم البراند بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.name_ar?.message}
            validateStatus={errors?.name_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>اسم البراند بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name_en"
            type="text"
            placeholder={'اسم البراند بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.name_en?.message}
            validateStatus={errors?.name_en ? 'error' : ''}
            control={control}
          />
        </div>
        {/* <AntdTextField
            className="form-text-field"
            name="country_id"
            type="text"
            placeholder={'اسم البراند بالانجليزية...'}
            // label="الاســــم"
            defaultValue={"2"}
            errorMsg={errors?.country_id?.message}
            validateStatus={errors?.country_id ? 'error' : ''}
            control={control}
            style={{display:"none"}}
          /> */}
        <div className="select-label-wrap shared-app-select-option-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            حالة البراند
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`status`}
              errorMsg={errors?.status && errors.status.message}
              validateStatus={errors?.status ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="حالة البراند"
              options={
                getBrandsStatusArr()?.length > 0 &&
                getBrandsStatusArr().map((obj) => ({
                  title: obj?.name,
                  value: obj?.id
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="brand-form"
            />
          </div>
          <div className="file-inputs-wrap">
            <FileInput
              name="icon"
              label={
                <p
                  className="outside-label"
                  style={{
                    color: '#404041',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    marginBottom: 8,
                    paddingRight: 8
                  }}
                >
                  <img src={imageIcon} alt="name" />
                  صورة البراند
                </p>
              }
              accept="image/*"
              multiple={false}
              setValue={setValue}
              watch={watch}
              register={register}
              unregister={unregister}
              // setUrls={setUrls}
              dropzoneText="اسحب الصورة وضعها هنا ..."
              className="product-images-dropzone"
              dropzoneUrls={
                selectedBrandTableRow?.image
                  ? [{ url: selectedBrandTableRow.image }]
                  : []
              }
              canDelete={false}
              showError={false}
              errorMsg={errors?.image?.message}
              files={files}
              setFiles={setFiles}
            />
          </div>
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(setBrandFormOpenedAction(false));
              reset();
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>
      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default BrandForm;
