import axios from 'axios';
import apisUrls from '../urls';

export const createWebsitePackageApi = (values, edit = false) => {
  const url = !edit
    ? apisUrls?.createWebsitePackageUrl
    : apisUrls?.updateWebsitePackageUrl;

  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const changeWebsitePackageStatusApi = (values) => {
  return axios.post(apisUrls?.changeWebsitePackageStatus, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteWebsitePackageApi = (values) => {
  return axios.post(apisUrls?.deleteWebsitePackageUrl, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getAllWebsitePackagesApi = (filterObj) => {
  return axios.get(apisUrls?.getWebsitePackagesUrl(filterObj));
};

export const getSingleWebsitePackageApi = (filterObj) => {
  return axios.get(apisUrls?.getSingleWebsitePackageUrl(filterObj));
};
