import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import accountTypes from '../../../const-values/accountTypes';
import {
  employeesPermissionsValues,
  storePermissionsValues
} from '../../../const-values/permissions';
// import { ChatProvider } from '../../../contexts/chat-context/ChatContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import AppPackagesPage from '../../../pages/app-packages/AppPackagesPage';
import HomePage from '../../../pages/home-page/HomePage';
import SettingsPage from '../../../pages/settings-page/SettingsPage';
import ClientsPage from '../../../pages/clients-page/ClientsPage';
// import UserChangePasswordPage from '../../../pages/user-profile-menu-pages/user-change-password-page/UserChangePasswordPage';
import protectMe from '../../../utils/protectMe';
import merchentRouterLinks from '../merchent-routes/merchentRouterLinks';
import routerLinks from '../routerLinks';
import ArchivePage from '../../../pages/archive-page/ArchivePage';
import InfluencersPage from '../../../pages/influencers-page/InfluencersPage';
import ProfilePage from '../../../pages/profile-page/ProfilePage';
import SingleClientPage from '../../../pages/clients-page/SingleClientPage';
import AdminFormPage from '../../../pages/profile-page/AdminFormPage';
import EditProfilePage from '../../../pages/edit-profile-page/EditProfilePage';
import PaymentTypesPage from '../../../pages/payment-types-page/PaymentTypesPage';
import SingleInfluencerPage from '../../../pages/influencers-page/SingleInfluencerPage';
import WebsiteServicesPage from '../../../pages/website-services-page/WebsiteServicesPage';
import WebsitePartnersPage from '../../../pages/website-partners-page/WebsitePartnersPage';
import WebsitePackagesPage from '../../../pages/website-packages-page/WebsitePackagesPage';
import WebsiteContactsPage from '../../../pages/website-contacts-page/WebsiteContactsPage';
import ExperiencesPage from '../../../pages/experiences-page/ExperiencesPage';
import WebsiteSettingsPage from '../../../pages/website-settings-page/WebsiteSettingsPage';
import AppGuidePage from '../../../pages/app-guide-page/AppGuidePage';
import DevicesPage from '../../../pages/app-packages/DevicesPage';
import CountryPage from '../../../pages/country-page/CountryPage.jsx';
import WebsiteRequestsPage from '../../../pages/website-requests-page/WebsiteRequestsPage.jsx';

const SharedRoutes = () => {
  const { user, loggedIn } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    editStores,
    deleteStores,
    addStores,
    reviewOrders,
    addEmployees,
    reviewStatistics
  } = storePermissionsValues(t);
  const { addProducts, editProducts, deleteProducts } =
    employeesPermissionsValues(t);
  const checkAuthedEmployeeRoute = ({
    rolesArr,
    supposedRouteRole,
    authed
  }) => {
    if (rolesArr?.length > 0) {
      // check permission
      if (
        rolesArr.includes(editStores) ||
        rolesArr.includes(deleteStores) ||
        rolesArr.includes(addStores) ||
        rolesArr.includes(reviewOrders) ||
        rolesArr.includes(addEmployees) ||
        rolesArr.includes(reviewStatistics) ||
        rolesArr.includes(addProducts) ||
        rolesArr.includes(editProducts) ||
        rolesArr.includes(deleteProducts)
      ) {
        // check if authed by default like view stores route

        if (authed) {
          return accountTypes()?.employee;
        } else if (rolesArr.includes(supposedRouteRole))
          return accountTypes()?.employee;
      }
      return null;
    }
  };

  return [
    // protectMe(
    //   routerLinks?.merchentDashboard,
    //   <MerchentDashboardPage />,
    //   1,
    //   routerLinks?.signinPage,
    //   loggedIn,
    //   user
    // ),
    protectMe(
      routerLinks?.profileRoute,
      <ProfilePage />,
      'main_profile_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.editProfileRoute,
      <EditProfilePage />,
      'edit_profile_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),

    protectMe(
      routerLinks?.homeRoute,
      <HomePage />,
      'main_dashboard_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.settingsRoute,
      <SettingsPage />,
      'settings_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.createAdminFormRoute,
      <AdminFormPage />,
      'create_admin_page_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.updateAdminFormRoute(),
      <AdminFormPage />,
      'update_admin_page_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.appPackagesRoute,
      <AppPackagesPage />,
      'app_packages_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.devicesRoute,
      <DevicesPage />,
      'devices_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.appClientsRoute,
      <ClientsPage />,
      'app_clients_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.appSingleClientRoute(),
      <SingleClientPage />,
      'app_client_details_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.appArchivesRoute,
      <ArchivePage />,
      'app_archive_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.appCountryRoute,
      <CountryPage />,
      'app_country_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.appAdminRoute,
      <ProfilePage />,
      'app_admin_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.appGuideRoute,
      <AppGuidePage />,
      'app_guide_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.appPaymentTypesRoute,
      <PaymentTypesPage />,
      'payment_types_page_key1',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.appAgentsRoute,
      <InfluencersPage />,
      'app_influencers_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.websiteRequests,
      <WebsiteRequestsPage />,
      'website_requests_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.singleInfluencerRoute(),
      <SingleInfluencerPage />,
      'influencer_details_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    //
    // website routes
    protectMe(
      routerLinks?.websitesServicesRoute,
      <WebsiteServicesPage />,
      'website_services_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.websitesServicesFeatruesRoute,
      <WebsiteServicesPage />,
      'website_services_features_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.websitePartnersRoute,
      <WebsitePartnersPage />,
      'website_partners_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.websitePackagesRoute,
      <WebsitePackagesPage />,
      'website_packages_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.websiteContactsRoute,
      <WebsiteContactsPage />,
      'website_packages_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.websiteStartExpRoute,
      <ExperiencesPage />,
      'website_experiences_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    protectMe(
      routerLinks?.websiteAdminPanelRoute,
      <WebsiteSettingsPage />,
      'website_admin_panel_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [],
      true,
      null,
      loggedIn // check if loggedin then its authed route
    ),
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    protectMe(
      routerLinks?.merchentStores,
      <h1>Merchent stores page</h1>,
      'merchent_stores_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent, accountTypes()?.employee]
    ),
    // new store route
    protectMe(
      routerLinks?.merchentNewStore,
      <h1>New store page</h1>,
      'merchent_new_store_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRole: storePermissionsValues(t)?.addStores,
          authed: false
        })
      ]
    ),
    // edit store route
    protectMe(
      routerLinks?.merchentEditStore(),
      <h1>Newe store page</h1>,
      'new_store_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRole: storePermissionsValues(t)?.editStores,
          authed: false
        })
      ]
    ),

    // account settings
    protectMe(
      merchentRouterLinks?.merchentSettings,
      <h1>Merchent settings page</h1>,
      'merchent_settings_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRole: null,
          authed: true
        })
      ],
      true,
      [
        // nested routes
        protectMe(
          merchentRouterLinks?.merchentSettingsNestedAccount,
          <h1>Merchent settingss form</h1>,
          'settings-account-route_key',
          routerLinks?.signinPage,
          loggedIn,
          user,
          [
            // accountTypes()?.user,
            accountTypes()?.merchent,
            checkAuthedEmployeeRoute({
              rolesArr:
                user?.storeRoles?.length >= 0 &&
                user?.employeeRolesArray?.length >= 0
                  ? [...user?.storeRoles, ...user?.employeeRolesArray]
                  : [],
              supposedRouteRole: null,
              authed: true
            })
          ],
          false
        ),
        protectMe(
          merchentRouterLinks?.nesetedSaloonSettings,
          <h1>Sallon settings form</h1>,
          'merchent_saloon_settings_route',
          routerLinks?.signinPage,
          loggedIn,
          user,
          [
            // accountTypes()?.user,
            accountTypes()?.merchent
          ],
          false
        ),
        protectMe(
          merchentRouterLinks?.nestedChangePassword,
          <h1>saloon change password page content</h1>,
          'merchent_change_password_route',
          routerLinks?.signinPage,
          loggedIn,
          user,
          [
            // accountTypes()?.user,
            accountTypes()?.merchent,
            checkAuthedEmployeeRoute({
              rolesArr:
                user?.storeRoles?.length >= 0 &&
                user?.employeeRolesArray?.length >= 0
                  ? [...user?.storeRoles, ...user?.employeeRolesArray]
                  : [],
              supposedRouteRole: null,
              authed: true
            })
          ],
          false
        )
      ]
    )
  ];
};

export default SharedRoutes;
