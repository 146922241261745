/* eslint-disable eqeqeq */

import CustomImage from '../../common/custom-image/CustomImage';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import medalIcon from '../../assets/imgs/icons/packages-icons/medal.svg';
import posIcon from '../../assets/imgs/icons/packages-icons/pos-icon.svg';
import CommonTag from '../../common/common-tag/CommonTag';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import { subscriptionStatus } from '../../const-values/subscriptionStatus';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import { Link } from 'react-router-dom';
import sharedRouterLinks from '../../components/app/shared-routes/sharedRouterLinks';

const WebsiteRequestsTableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  setDeleteModalOpened,
  i18n,
  t,
  ...props
}) => {
  const handleEdit = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };
  const handleDeleteInfluencer = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setDeleteModalOpened(true);
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <Link
            to={sharedRouterLinks?.singleInfluencerRoute(record?.id || '')}
            className="action-btn view"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="#196c10"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
            </svg>
          </Link>

          <div className="main-btns-wrapper">
            <button
              className="action-btn edit"
              onClick={() => handleDeleteInfluencer(record)}
            >
              <TrashIcon color="#196c10" />
            </button>
            <button
              className="action-btn edit"
              onClick={() => handleEdit(record)}
            >
              <EditIcon color="#196c10" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  // const SingleTag = (item) => {
  //   if (item?.pacakge?.name) {
  //     return ()
  //   }
  // }

  // const renderSubscriptionTags = arr => {
  //   return (
  //     arr.map(item => {
  //       return (
  //         <SingleTag key={item?.id} item={item} />
  //       )
  //     })
  //   )
  // }

  const RenderTag = ({ iconSrc, title, className }) => {
    return <CommonTag title={title} iconSrc={iconSrc} className={className} />;
  };

  return [

    {
      title: '#',
      dataIndex: 'id',
      render: (_, record) => {
        return record?.id || '----';
      }
    },
    {
      title: 'الاسم الاول',
      dataIndex: 'firstName',
      render: (_, record) => {
        return record?.firstName || '----';
      }
    }, {
      title: 'الاسم العائلة',
      dataIndex: 'familyName',
      render: (_, record) => {
        return record?.familyName || '----';
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      render: (_, record) => {
        return record?.phone || '----';
      }
    },
    {
      title: 'البريد الاكترونى',
      dataIndex: 'email',
      render: (_, record) => {
        return record?.email || '----';
      }
    },
    {
      title: 'اسم الشركة',
      dataIndex: 'companyName',
      render: (_, record) => {
        return record?.companyName || '----';
      }
    },
    {
      title: 'عدد الفروع',
      dataIndex: 'branchesNumber',
      render: (_, record) => {
        return record?.branchesNumber || '----';
      }
    },
   
    {
      title: 'وقت الطلب',
      dataIndex: 'created_at',
      render: (_, record) => {
        return record?.created_at || '----';
      }
    },
  
  ];
};

export default  WebsiteRequestsTableColumns;
