import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createInfluencerAction,
  deleteInfluencerAction,
  getInfluencersAction,
  getInfluencersSocialsAction,
  getSingleInfluencerAction,
  updateInfluencerAction
} from './influencersSliceThunkApi';

const initialState = {
  /////////////////////
  isLoadingTableData: false,
  fetchedTableData: [],
  fetchedSingleInfluencer: null,
  fetchedSocials: [],
  fetchDataCount: 0,
  postFormModalOpened: false,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  invoiceModalOpened: false,
  selectedInfluencerInvoice: null,

  //
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const influencersSlice = createSlice({
  name: 'influencersSlice',
  initialState,
  reducers: {
    setPostFormModalOpened: (sliceState, action) => {
      window.scrollTo(0, 0);
      sliceState.postFormModalOpened = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setInfluencerInvoiceModalOpened: (sliceState, action) => {
      sliceState.invoiceModalOpened = action.payload;
    },
    setSelectedInfluencerInvoice: (sliceState, action) => {
      sliceState.selectedInfluencerInvoice = action.payload;
    }
  },
  extraReducers: {
    // get influencers
    [getInfluencersAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getInfluencersAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data?.data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getInfluencersAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    // get single client
    [getSingleInfluencerAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getSingleInfluencerAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSingleInfluencer = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getSingleInfluencerAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    // get influencers socials
    [getInfluencersSocialsAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getInfluencersSocialsAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSocials = data;
    },
    [getInfluencersSocialsAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    // create influencer
    [createInfluencerAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createInfluencerAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [createInfluencerAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // update influencer
    [updateInfluencerAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateInfluencerAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [updateInfluencerAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    // delete influencer
    [deleteInfluencerAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteInfluencerAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteInfluencerAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const influencersReducer = influencersSlice.reducer;

export const {
  setPostFormModalOpened,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setInfluencerInvoiceModalOpened,
  setSelectedInfluencerInvoice
} = influencersSlice.actions;

export default influencersReducer;
