/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import CustomImage from '../../common/custom-image/CustomImage';
import CustomLoader from '../../common/custom-loader/CustomLoader';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import {
  setDeleteModalOpened,
  setDeviceFormOpenedAction,
  setSelectedTableRow
} from '../../redux/devices-slice/devicesSlice';
import { Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const DevicesTable = ({ fetchedDevices }) => {
  const dispatch = useDispatch();
  const deleteModalOpened = useSelector(
    (store) => store.devices.deleteModalOpened
  );
  const isLoadingTableData = useSelector(
    (store) => store.devices.isLoadingTableData
  );
  // const isSubmitting = useSelector((store) => store.devices.isSubmitting);

  const deviceFormOpened = useSelector(
    (store) => store.devices.deviceFormOpened
  );
  const { t } = useTranslation();
  const DeviceItem = ({ item }) => {
    return (
      <div className="device-item">
        <CustomImage src={item?.image} className="device-img-wrapper" />
        <div className="item-content">
          <div className="device-details">
            <Row
              style={{
                justifyContent: 'space-between'
              }}
            >
              <p className="name">{item?.name}</p>
              <Tag
                style={{
                  borderRadius: '5px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  padding: '0 5px',
                  height: '24px',
                  lineHeight: '20px',
                  textAlign: 'center',
                  marginTop: '4px'
                }}
                color={Number(item?.price) === 0 ? 'green' : 'cyan'}
              >
                {Number(item?.price) > 0
                  ? `${Number(item?.price).toLocaleString()} ${t(
                      'currency.sar'
                    )}`
                  : 'مجاني'}
              </Tag>
            </Row>
            <p className="model-p">{`${item?.model || ''} ${
              item?.brand?.name || ''
            }`}</p>
          </div>
          <div className="item-btns-wrapper">
            <button
              onClick={() => {
                dispatch(setSelectedTableRow(item));
                dispatch(setDeleteModalOpened(true));
              }}
              className="delete-btn"
            >
              <TrashIcon color="rgba(64, 64, 65, 0.6)" />
              حذف
            </button>
            <button
              className="edit-btn"
              onClick={() => {
                dispatch(setSelectedTableRow(item));
                dispatch(setDeviceFormOpenedAction(true));
              }}
            >
              <EditIcon color="rgba(64, 64, 65, 0.6)" />
              تعديل
            </button>
          </div>
        </div>
      </div>
    );
  };
  const renderDevicesList = useMemo(() => {
    return () => {
      return (
        <div className="devices-list-wrapper">
          {fetchedDevices?.data?.map((item) => {
            return <DeviceItem key={item.id} item={item} />;
          })}
        </div>
      );
    };
  }, [fetchedDevices]);

  // const renderDevicesList = () => {
  //   return (
  //     <div className="devices-list-wrapper">
  //       {fetchedDevices.map((item) => {
  //         return <DeviceItem key={item.id} {...item} />;
  //       })}
  //     </div>
  //   );
  // };

  if (isLoadingTableData) {
    return <CustomLoader />;
  } else if (!fetchedDevices || fetchedDevices?.length === 0) {
    return (
      <div className="empty-devices-wrapper">
        <CustomEmpty>
          <p>قم بإضافة أجهزتك هنا</p>
        </CustomEmpty>
      </div>
    );
  } else if (fetchedDevices?.data?.length > 0) return renderDevicesList();
  return null;
};

export default DevicesTable;
